import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Grid, { GridSpacing } from '@material-ui/core/Grid';

import { ComponentWithChildren } from '../../models/models';

export interface ResponseGridProps extends ComponentWithChildren {
  /**
   * An integer between 0 to 10 inclusive that sets the spacing between the grid items as a multiple of 8px
   */
  spacing?: GridSpacing
}

const ResponseGrid: React.FC<ResponseGridProps> = ({
  children,
  spacing = 2
}) => {
  const classes = useStyles();

  return (
    <Grid container spacing={spacing} className={classes.root}>
      {children}
    </Grid>
  );
};

const useStyles = makeStyles(
  () =>
    createStyles({
      root: {}
    }),
  { name: 'Mui-ResponseGrid' }
);
export default ResponseGrid;