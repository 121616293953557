import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import {
  ComponentWithChildren
} from '../../models/models';

const Body: React.FC<ComponentWithChildren> = ({
  children
}) => {
  const classes = useStyles();
  return <main className={classes.root}>{children}</main>;
};

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        background: theme.palette.background.default,
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        overflowY: 'auto',
        overflowX: 'hidden',
        padding: theme.spacing(4, 3, 0, 3),
        gridArea: 'Body',
        '@media (min-width: 512px)': {
          padding: theme.spacing(4, 10, 0, 10)
        },
        '@media (min-width: 1024px)': {
          padding: theme.spacing(4, 15, 0, 15)
        }
      }
    }),
  { name: 'Mui-Body' }
);

export default Body;
