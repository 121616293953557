import { LogglyTracker } from 'loggly-jslogger';
import { getConfig } from './config';

const config = getConfig();

const logger = new LogglyTracker();
logger.push({
  logglyKey: config.segmentWriteKey,
  tag: 'RadioSDK'
});

export default logger;
