import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { ComponentBase } from '../../models/models';

export interface Props extends ComponentBase {
  /**
   * What the button says.
   */
  label?: string,
  /**
   * What the button does.
   */
  onClick: () => void
  /**
   * Prop that allows to hide SaveAndExitButton.
   */
  hideSaveAndExit?: boolean;
  /**
   * Unique ID used by the backend to catch user behavior.
   */
  uuid?: string; 
}

const SaveAndExitButton: React.FC<Props> = ({
  label = 'Save and exit',
  onClick,
  hideSaveAndExit = false,
}) => {
  
  const classes = useStyles();
  const buttonClasses = [classes.root];

  if (hideSaveAndExit) {
    buttonClasses.push(classes.hidden);
  }

  return <Button variant="outlined" size="small" className={buttonClasses.join(' ')} onClick={() => onClick()}>{label}</Button>;
};

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      borderColor: theme.palette.primary.contrastText,
      color: theme.palette.primary.contrastText,
      textTransform: 'none',
    },
    hidden: {
      visibility: 'hidden'
    }
  }), { name: 'Mui-SaveAndExitButton' }
);

export default SaveAndExitButton;