import * as React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { ComponentBase } from '../../models/models';
import { useRadioDispatch } from '../Core/radioContext';

export interface Props extends ComponentBase {
  /**
   * Unique ID used by the backend to catch user behavior.
   */
  uuid?: string;
  /**
   * URL to a logo.
   */
  logoUrl?: string;
}

const LogoTopNav: React.FC<Props> = ({
  logoUrl,
  uuid
}) => {
  const { logResponseAndComplete } = useRadioDispatch();
  const classes = useStyles();

  const handleClick = () => {
    if (uuid) {
      logResponseAndComplete({
        componentId: uuid
      });
    }
  };

  return (
    <div className={classes.root}>
      <button type="button" onClick={handleClick} className={classes.button}>
        <img
          src={
            logoUrl ||
            'https://assets.harbormaster.dev/yourcase/images/yc_logo.svg'
          }
          alt="logo"
          data-testid="logo"
          className={classes.logo}
        />
      </button>
    </div>
  );
};

const useStyles = makeStyles(
  () =>
    createStyles({
      root: {
        display: 'flex',
        alignItems: 'center',
        height: '100%'
      },
      logo: {
        display: 'flex', // centers it better vertically
        width: '120px'
      },
      button: {
        // remove default styles
        border: 'none',
        padding: 0,
        cursor: 'pointer',
        outline: 'inherit',
        backgroundColor: 'inherit'
      }
    }),
  { name: 'Mui-LogoTopNav' }
);

export default LogoTopNav;
