import React from 'react';
import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import { ComponentBase } from '../../models/models';

export interface Props extends ComponentBase {
  /**
   * img url string.
   */
  imageUrl?: string;
  /**
   * width value for image.
   */
  imageWidth?: string;
  /**
   * img alt value.
   */
  imageAlt?: string;
  /**
   * value located in the top of the card.
   */
  topValue: string;
  /**
   * value located in the bottom of the card.
   */
  bottomValue?: string;
}

const CardAtomWithImage: React.FC<Props> = ({
  imageUrl,
  imageWidth = '30px',
  imageAlt = '',
  topValue,
  bottomValue
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      { imageUrl && (
        <div className={classes.imgContainer}>
          <img
            src={imageUrl}
            alt={imageAlt}
            width={imageWidth}
            className={classes.img}
          />
        </div>
      )}
      <div className={classes.contentContainer}>
        <div className={classes.topValueContainer}>
          <span className={classes.topValue}>{topValue}</span>
        </div>
        {
          bottomValue && (
            <div className={classes.bottomValueContainer}>
              <span className={classes.bottomValue}>{bottomValue}</span>
            </div>
          )
        }
      </div>
    </div>
  );
};

const useStyles = makeStyles<Theme>(
  // @ts-ignore Mui bug with spreading theme variables.
  (theme) =>
    createStyles({
      root: {
        display: 'flex',
        padding: '16px',
        boxShadow: theme.shadows[2],
        borderRadius: '8px',
        '&:focus-visible': {
          outline: 'none' // dev-defined a11y color
        },
        '&:-moz-focusring': {
          outline: 'none' // dev-defined a11y color
        }
      },
      imgContainer: {
        paddingRight: '16px'
      },
      img: {
      },
      contentContainer: {
        flex: 1
      },
      topValueContainer: {
        marginBottom: '8px'
      },
      topValue: {
        color: theme.palette.primary.main,
        ...theme.typography.body1,
        display: 'block',
        textAlign: 'left'
      },
      bottomValueContainer: {},
      bottomValue: {
        color: theme.palette.secondary.main,
        ...theme.typography.body2,
        display: 'block',
        textAlign: 'left'
      }
    }),
  { name: 'Mui-CardAtomWithImage' }
);

export default CardAtomWithImage;
