import React from 'react';
import parse from 'html-react-parser';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import { DefaultCloseIcon } from '../../assets/DefaultIcons';
import { ComponentBase } from '../../models/models';

export interface Props extends ComponentBase {
  /**
   * Headline text.
   */
  headline?: string;
  /**
   * close component.
   */
  closeType?: 'icon' | 'button';
  /**
   * source for custom close icon.
   */
  closeIconSource?: 'string';
  /**
   * Body text.
   */
  body?: string;
  /**
   * Flag for open state.
   */
  open: boolean;
  /**
   * Function to close modal.
   */
  handleClose: () => void;
}

const SimpleModal: React.FC<Props> = ({
  headline = '',
  body = '',
  open,
  handleClose,
  closeType = 'icon',
  closeIconSource,
  children
}) => {
  // @ts-ignore No idea why TS sometimes doesn't like spread type…
  const classes = useStyles();

  return (
    <Dialog
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      onClose={handleClose}
      open={open}
      classes={{
        paper: classes.root
      }}
    >
      <DialogTitle disableTypography className={classes.header}>
        {closeType === 'icon' && (
          <IconButton
            aria-label="close"
            className={classes.closeTopButton}
            onClick={handleClose}
          >
            {closeIconSource ? (
              <img src={closeIconSource} alt="close-icon" />
            ) : (
              <DefaultCloseIcon />
            )}
          </IconButton>
        )}
        {headline && (
          <h2 id="modal-title" className={classes.headerTitle}>
            {headline}
          </h2>
        )}
      </DialogTitle>
      <DialogContent id="modal-description" className={classes.content}>
        {parse(body)}
        {children}
      </DialogContent>
      {closeType === 'button' && (
        <button
          type="button"
          className={classes.closeButtonFooter}
          onClick={handleClose}
        >
          Close
        </button>
      )}
    </Dialog>
  );
};

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        borderRadius: '8px',
        maxHeight: '650px'
      },
      closeButtonFooter: {
        background: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        border: 'none',
        borderRadius: '8px',
        fontFamily: theme.typography.fontFamily,
        fontSize: '1rem',
        transition: '.1s background',
        padding: '12px 16px',
        cursor: 'pointer',
        '&:focus-visible': {
          outline: '3px solid #005FCC' // dev-defined a11y color
        },
        '&:-moz-focusring': {
          outline: '3px solid #005FCC' // dev-defined a11y color
        }
      },
      closeTopButton: {
        '& span svg path': {
          stroke: theme.palette.primary.main
        }
      },
      header: {
        alignItems: 'center',
        borderBottom: `1px solid ${theme.palette.divider}`,
        display: 'flex',
        flexDirection: 'row-reverse',
        padding: theme.spacing(2)
      },
      headerTitle: {
        ...theme.typography.h6,
        flex: 1,
        margin: 0
      },
      content: {
        ...theme.typography.body1,
        padding: theme.spacing(2),
        paddingTop: 0,
        '& a': {
          color: theme.palette.secondary.main
        }
      }
    }),
  { name: 'Mui-SimpleModal' }
);

export default SimpleModal;
