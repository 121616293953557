import * as React from 'react';
import { IconButton } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { ComponentBase } from '../../models/models';
import { DefaultCloseIcon } from '../../assets/DefaultIcons';

export interface Props extends ComponentBase {
  /**
   * What happens when the button is clicked.
   */
  onClick: () => void;
  /**
   * Prop that allows to hide XOutButton.
   */
  hideXOutButton?: boolean;
  /**
   * Unique ID used by the backend to catch user behavior.
   */
  uuid?: string; 
}

const XOutButton: React.FC<Props> = ({
  onClick,
  hideXOutButton = false,
}) => {

  const classes = useStyles();

  return (
    <div className={classes.root}>
      {!hideXOutButton && (
        <IconButton aria-label="Close" onClick={() => onClick()} className={classes.iconButton}>
          <DefaultCloseIcon />
        </IconButton>
      )}
    </div>
  );
};

const useStyles = makeStyles(
  () =>
    createStyles({
      root: {
        display: 'flex',
        height: '100%',
        alignItems: 'center'
      },
      iconButton: {
        '& span': {
          '& svg': {
            width: '20px',
            height: 'auto'
          }
        }
      }
    }),
  { name: 'Mui-XOutButton' }
);

export default XOutButton;
