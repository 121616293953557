import React from 'react';
import {
  StylesProvider,
  createGenerateClassName
} from '@material-ui/core/styles';
import { HelmetProvider } from 'react-helmet-async';

import ConfigSetter from './ConfigSetter';
import { ExperienceThemeProvider } from '../../styles/experienceThemeContext';
import { NavigationProvider } from './navigationContext';
import { RadioProvider } from './radioContext';
import { NoteProvider } from '../Notifications/noteContext';
import RadioTuner from './RadioTuner';

const generateClassName = createGenerateClassName({
  productionPrefix: 'radioSdk-jss',
  disableGlobal: true
});

type Props = {
  config?: Record<string, string | boolean>;
};

const RadioSDK: React.FC<Props> = ({ config = {} }) => {
  // React.useEffect(() => {
  //   const onCloseTab = (e:BeforeUnloadEvent) => {
  //     e.preventDefault();
  //     e.returnValue = '';
  //   };
  //   window.addEventListener('beforeunload', onCloseTab);
  //   return () => {
  //     window.removeEventListener('beforeunload', onCloseTab);
  //   };
  // }, []);

  return (
    <StylesProvider generateClassName={generateClassName}>
      <ExperienceThemeProvider>
        <ConfigSetter config={config}>
          <HelmetProvider>
            <NoteProvider>
              <RadioProvider>
                <NavigationProvider>
                  <RadioTuner />
                </NavigationProvider>
              </RadioProvider>
            </NoteProvider>
          </HelmetProvider>
        </ConfigSetter>
      </ExperienceThemeProvider>
    </StylesProvider>
  );
};

export default RadioSDK;
