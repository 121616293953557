import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { ComponentWithChildren } from '../../models/models';

const LeftPanel: React.FC<ComponentWithChildren> = ({
  children
}) => {
  const classes = useStyles();
  return <aside className={classes.root}>{children}</aside>;
};

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        background: theme.palette.primary.main,
        boxSizing: 'border-box',
        gridArea: 'LeftPanel',
        padding: theme.spacing(4, 2, 0, 2),
        width: '260px',
        '@media (max-width: 1024px)': {
          display: 'none'
        }
      }
    }),
  { name: 'Mui-LeftPanel' }
);

export default LeftPanel;
