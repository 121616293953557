// import { darken } from '@material-ui/core/styles';
import * as palette from './colors';
import {
  typographyVariants,
  customTypographyVariants,
  fontStack
} from './typography';
import { shadows } from './shadows';

const variants = {
  'Mui-SaveAndExitLeftPanel': {
    root: {
      ...customTypographyVariants.labelSmall,
      borderColor: palette.white,
      color: palette.white,
      borderRadius: '.25re',
      padding: '8px 12px'
    }
  },
  'Mui-BodyContent-BottomText': {
    root: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: '800px',
      justifyContent: 'center',
      '@media (max-width: 960px)': {
        justifyContent: 'flex-end'
      }
    }
  },
  'Mui-BodyContent-Large': {
    root: {
      height: 'auto',
      maxWidth: '800px',
      '@media (max-width: 600px)': {
        width: 'calc(100% + 48px)'
      }
    }
  },
  'Mui-BodyContent-Success': {
    root: {
      width: '100%',
      backgroundColor: palette.white,
      padding: '24px',
      maxWidth: '800px'
    }
  },
  'Mui-Section-PaddingLess': {
    root: {
      padding: '0 !important'
    }
  },
  'Mui-Headline-PaddingLess': {
    root: {
      ...typographyVariants.h1,
      paddingBottom: '4px !important',
      margin: 0,

      '@media (max-width: 768px)': {
        fontSize: 22
      }
    }
  },
  'Mui-Headline-Transition': {
    root: {
      color: palette.white,
      ...typographyVariants.h1,
      '@media (max-width: 600px)': {
        marginBottom: '-28px'
      },

      '@media (max-width: 768px)': {
        fontSize: 22
      }
    }
  },
  'Mui-FabBase-Transition': {
    default: {
      backgroundColor: palette.knollBurgundy[500],
      color: palette.knollGray.main,
      borderRadius: '.5rem',
      '&:hover': {
        background: `${palette.knollBurgundy[600]} !important`,
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: palette.knollBurgundy[500]
        }
      },
      '&:active': {
        background: `${palette.knollBurgundy[600]} !important`
      }
    }
  }
};
const keyValuePairs = {
  'Mui-KeyValuePair': {
    key: {
      ...customTypographyVariants.sh2
    },
    value: {
      ...customTypographyVariants.sh1
    }
  },
  'Mui-KeyValuePair-Variant2': {
    root: {
      flexDirection: 'column',
      alignItems: 'flex-start'
    },
    key: {
      ...customTypographyVariants.bodyLarge
    },
    value: {
      ...customTypographyVariants.sh1
    }
  },
  'Mui-KeyValuePair-Variant3': {
    root: {
      flexDirection: 'column',
      alignItems: 'flex-start'
    },
    key: {
      ...customTypographyVariants.bodyRegular
    },
    value: {
      ...customTypographyVariants.sh2
    }
  }
};

const common = {
  'Mui-SaveAndExitButton': {
    root: {
      ...customTypographyVariants.labelSmall,
      borderColor: palette.knollGold[500],
      color: palette.knollGray.main,
      borderRadius: '.25rem',
      padding: '8px 12px',
      '&:hover': {
        backgroundColor: `${palette.knollGold[500]} !important`,
        color: palette.knollGray.main,
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: palette.white,
          color: palette.knollGray.main
        }
      },
      '&:active': {
        backgroundColor: `${palette.knollGold[500]} !important`
      }
    }
  },
  'Mui-ChipLabel': {
    root: {
      ...customTypographyVariants.labelSmall,
      padding: '3px 4px',
      borderRadius: '.125rem !important',
      height: 'inherit',
      '&$error': {
        background: palette.knollRed[50],
        color: palette.knollRed[500]
      },
      '&$info': {
        background: palette.knollBlue[50],
        color: palette.knollBlue[500]
      },
      '&$warning': {
        background: palette.knollYellow[50],
        color: palette.knollYellow[500]
      },
      '&$success': {
        background: palette.success[50],
        color: palette.success[500]
      }
    },
    label: {
      padding: 0
    }
  }
};

const layout = {
  'Mui-Body': {
    root: {
      background: palette.white
      // background: palette.keleGray.light
    }
  },

  'Mui-Footer1': {
    root: {
      backgroundColor: 'transparent',
      padding: '16px 16px 8px !important',
      // borderTop: `1px solid ${palette.mrNavy[100]}`,
      height: 'auto',
      '@media (min-width: 1024px)': {
        left: '354px',
        width: 'calc(100% - 354px)'
      }
    }
  },

  'Mui-Body-Carousel': {
    root: {
      // background: palette.mrNavy[50]
    }
  },

  'Mui-TopNavigation': {
    root: {
      background: palette.white
    }
  },

  // For Modal backdrop
  MuiBackdrop: {
    root: {
      backgroundColor: 'rgba(11, 24, 55, 0.8)'
    }
  }
};

const body = {
  'Mui-BodyContent': {
    root: {
      maxWidth: '800px'
    }
  },
  'Mui-Section': {
    root: {
      '&:last-child': {
        '@media (max-width: 600px)': {
          paddingBottom: '128px'
        }
      }
    }
  }
};

const footerNavigation = {
  'Mui-FooterContent': {
    root: {
      display: 'flex',
      flexDirection: 'column-reverse',
      maxWidth: '800px',
      '& > *:not(:first-child)': {
        marginBottom: '8px'
      },
      '@media (min-width: 600px)': {
        flexDirection: 'row',
        alignItems: 'center',
        '& > *:not(:last-child)': {
          marginRight: '16px'
        },
        '& > *:not(:first-child)': {
          margin: 0
        }
      }
    }
  },
  'Mui-FabBase': {
    root: {
      ...customTypographyVariants.sh2
    },
    default: {
      background: palette.knollBurgundy[500],
      border: '2px solid transparent',
      borderRadius: '.5rem',
      color: palette.white,
      '@media (pointer: fine)': {
        '&:hover': {
          boxShadow: shadows[4],
          background: palette.knollBurgundy[600],
          color: palette.white,
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            color: palette.white,
            background: palette.knollBurgundy[500]
          }
        }
      },
      '&:active': {
        boxShadow: shadows[0],
        background: palette.knollBurgundy[600]
      }
    },
    inverse: {
      border: `1px solid ${palette.knollBurgundy[500]}`,
      borderRadius: '.5rem',
      color: palette.knollGray.main,
      '@media (pointer: fine)': {
        '&:hover': {
          boxShadow: shadows[4],
          background: palette.knollBurgundy[500],
          color: palette.white,
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            background: palette.white
          }
        }
      },
      '&:active': {
        boxShadow: shadows[0]
      }
    },
    fabExtended: {
      height: '56px',
      '&$ghost': {
        height: '40px'
      }
    },
    fabDisabled: {
      border: '2px solid transparent !important',
      backgroundColor: `${palette.knollGray.light} !important`,
      color: `${palette.knollGray.disabled} !important`,
      '$ghost&': {
        backgroundColor: 'transparent !important',
        color: `${palette.knollBurgundy[500]} !important`
      }
    },
    fabFocusVisible: {
      border: `3px solid ${palette.knollBurgundy[500]}`
    },
    ghost: {
      backgroundColor: 'transparent',
      color: palette.knollGray.main,

      '&:hover': {
        backgroundColor: palette.white,
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: 'transparent'
        }
      },
      '&:active': {
        backgroundColor: 'transparent'
      }
    }
  }
};

const topNavigation = {
  'Mui-LogoTopNav': {
    logo: {
      width: '150px'
    }
  }
};

const leftPanel = {
  'Mui-LeftPanel': {
    root: {
      background: palette.white,
      width: '354px'
    }
  },
  'Mui-ExperienceProgressContainer': {
    root: {
      marginTop: '24px',
      marginLeft: '-16px',
      width: '354px',
      boxSizing: 'border-box'
    }
  },
  'Mui-ProgressStage': {
    root: {
      padding: '0 28px',
      alignItems: 'center',
      marginBottom: '16px',
      '& path': {
        fill: palette.knollGray.main
      }
    },
    stepActive: {
      backgroundColor: palette.knollGray.main,
      '& path': {
        fill: palette.white
      }
    },
    stepCompleted: {
      '& path': {
        fill: 'none',
        stroke: palette.knollBurgundy[500]
      }
    },
    label: {
      // ...typography.caption,
      color: palette.knollGray.main,
      ...customTypographyVariants.bodyLarge,
      '$stepActive &': {
        color: palette.white
      },
      '$stepCompleted &': {
        color: palette.knollGray.main
      }
    }
  }
};

const prompt = {
  /**
   *
   * All of these are pulled from Figma but feel
   * small and "off", but it's a starting point. :)
   *
   */
  'Mui-Headline': {
    root: {
      ...typographyVariants.h1,
      color: palette.knollGray.main,
      margin: 0,
      paddingBottom: '24px',

      '@media (max-width: 768px)': {
        fontSize: 22
      }
    }
  },

  'Mui-Description': {
    root: {
      ...customTypographyVariants.bodyLarge,
      color: palette.knollGray.main,
      margin: 0,
      paddingBottom: '8px'
    },
    link: {
      ...customTypographyVariants.bodyLargeMedium,
      color: palette.knollBurgundy[500],
      textDecoration: 'none',
      transition: 'color .250s',
      '@media (pointer: fine)': {
        '&:hover': {
          filter: 'brightness(92%)'
        }
      }
    },
    unstyledButton: {
      color: palette.knollBurgundy[500],
      ...customTypographyVariants.bodyLargeMedium
    }
  },

  'Mui-ModalLink': {
    root: {
      ...customTypographyVariants.bodyLargeMedium,
      color: palette.knollGray.body,
      margin: 0,
      paddingBottom: '8px'
    },
    link: {
      ...customTypographyVariants.bodyLargeMedium,
      color: palette.knollBurgundy[500],
      textDecoration: 'none',
      transition: 'color .250s',
      '@media (pointer: fine)': {
        '&:hover': {
          filter: 'brightness(92%)'
        }
      }
    }
  }
};

const popOuts = {
  // 'Mui-SnackbarLongBase': {
  //   snackbar: {
  //     background: palette.ycGreen[500],
  //   },
  //   body: {
  //     ...typography.body2,
  //   },
  // },
  'Mui-SimpleModal': {
    root: {
      padding: '24px'
    },
    header: {
      borderBottom: 'none',
      padding: '0 0 16px'
    },
    closeButton: {
      alignSelf: 'flex-end',
      // height: 24,
      // width: 24,
      '& svg': {
        fill: 'red'
      }
    },
    headerTitle: {
      color: palette.knollGray.main,
      ...typographyVariants.h2,
      padding: 0
    },
    content: {
      color: palette.knollGray.main,
      ...customTypographyVariants.bodyLarge,
      padding: '0 0 24px',
      textAlign: 'justify',
      '& p:first-child': {
        marginTop: 0
      },
      '& p:last-child': {
        marginBottom: 0
      },
      '& a': {
        textDecoration: 'none',
        fontWeight: 500,
        color: palette.knollBurgundy[500],
        ...customTypographyVariants.bodyLargeMedium
      }
    },
    closeButtonFooter: {
      background: palette.knollBurgundy[500],
      border: '2px solid transparent',
      borderRadius: '.5rem',
      color: palette.knollGray.main,
      '@media (pointer: fine)': {
        '&:hover': {
          background: palette.knollBurgundy[600]
        }
      }
    }
  }
};

const responseAtoms = {
  'Mui-CardAtom': {
    root: {
      border: `2px solid ${palette.knollGray.body}`
    },
    checkedCard: {
      border: `2px solid ${palette.knollBurgundy[500]} !important`
    },
    topRow: {
      marginBottom: '-2px'
    },
    topLeftValue: {
      ...customTypographyVariants.bodyLargeMedium
    },
    topRightValue: {
      ...customTypographyVariants.bodyLarge
    },
    bottomLeftValue: {
      ...customTypographyVariants.labelSmall,
      color: palette.knollGray.body
    },
    bottomRightValue: {
      ...customTypographyVariants.labelSmall,
      color: palette.knollGray.body
    },
    stacked: {
      boxShadow: `0 10px 0 -6px ${palette.knollGray.body}`
    },
    grouped: {
      border: `1px solid ${palette.knollGray.body}`
    }
  },
  'Mui-CheckboxInput': {
    input: {
      '& path': {
        color: palette.knollGray.body
      }
    },
    colorPrimaryCheckbox: {
      '&$checkedCheckbox': {
        '& path': {
          color: `${palette.knollBurgundy[500]}`
        }
      }
    }
  },
  'Mui-RadioInput': {
    colorPrimaryRadio: {
      '&$checkedRadio': {
        color: palette.knollBurgundy[500]
      }
    }
  },
  'Mui-SingleSelectBase': {
    root: {
      '& .MuiInput-underline': {
        '&:hover:not(.Mui-disabled)::before': {
          borderBottom: `2px solid ${palette.knollBurgundy[600]}`
        },
        '&:before': {
          borderBottom: `1px solid ${palette.knollGray.body}`
        },
        '&:after': {
          borderBottom: `2px solid ${palette.knollBurgundy[500]}`
        }
      }
    },
    label: {
      color: palette.knollGray.main
    },
    placeholder: {
      color: `${palette.knollGray.body}`
    },
    input: {
      color: `${palette.knollGray.main} !important`
    },
    inputIcon: {
      fill: palette.knollGray.main
    },
    menu: {
      boxShadow: shadows[4],
      borderRadius: '.5rem'
    },
    menuItem: {
      color: palette.knollGray.main,
      '@media (pointer: fine)': {
        '&:hover': {
          background: `${palette.knollBurgundy[500]} !important`,
          color: palette.white
        }
      },
      '&:focus': {
        border: `2px solid ${palette.knollBurgundy[600]} !important`,
        color: palette.knollGray.main
      }
    },
    menuItemSelected: {
      background: `${palette.knollBurgundy[500]} !important`,
      color: palette.white
    },
    itemPrimaryText: {
      ...customTypographyVariants.bodyLarge
    },
    itemSecondaryText: {
      color: palette.knollGray.body
    }
  },
  'Mui-TypeInBase': {
    root: {
      '& .Mui-error': {
        color: palette.knollRed[500],
        '&:after': {
          borderBottomColor: palette.knollRed[500]
        }
      },
      '& .MuiSvgIcon-root': {
        '& path': {
          fill: palette.knollGray.main
        }
      }
    },
    label: {
      color: palette.knollGray.main
    },
    input: {
      color: `${palette.knollGray.main} !important`,
      caretColor: palette.knollGold[500],
      '&:before': {
        borderBottom: `1px solid ${palette.knollGray.body}`
      },
      '&:after': {
        borderBottom: `2px solid ${palette.knollBurgundy[500]}`
      },
      '& ::placeholder': {
        color: palette.knollGray.body,
        opacity: 1
      }
    },
    underline: {
      '&:hover:not(.Mui-disabled)::before': {
        borderBottom: `2px solid ${palette.knollGray.main}`
      }
    }
  },
  'Mui-TileButtonBase': {
    root: {
      background: 'transparent',
      border: `2px solid ${palette.knollGold[500]}`,
      borderRadius: '.5rem',
      color: palette.knollGray.main,
      '@media (pointer: fine)': {
        '&:hover': {
          boxShadow: shadows[4],
          background: palette.knollGold[500],
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            background: palette.white
          }
        }
      }
    },
    selectedOption: {
      background: palette.knollGold[500],
      border: `2px solid ${palette.knollGold[500]}`,
      color: palette.knollGray.main,
      '.multi-select &': {
        background: palette.knollGold[500],
        border: `2px solid ${palette.knollGold[500]}`,
        boxShadow: `0 0 0 1px ${palette.knollGold[500]}`,
        '@media (pointer: fine)': {
          '&:hover': {
            boxShadow: shadows[4]
          }
        }
      }
    },
    buttonLabel: {
      ...customTypographyVariants.bodyLargeMedium
    }
  }
};

const list = {
  'Mui-List': {
    root: {
      color: palette.knollGray.main,
      marginBottom: '44px',
      textAlign: 'center'
    },
    listContainer: {
      display: 'inline-block',
      padding: '16px 24px',
      textAlign: 'left'
    }
  }
};

const progress = {
  'Mui-ProgressBar': {
    root: {
      margin: '-24px 0 32px'
    },
    label: {
      color: palette.knollBurgundy[500]
    }
  }
};

const visual = {
  'Mui-Image': {
    root: {
      margin: '0 0 40px'
    }
  },
  'Mui-ParsedHTML': {
    root: {
      fontFamily: fontStack.openSans,
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '20px',
      color: palette.knollGray.main,
      '& a': {
        fontFamily: fontStack.openSans,
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '18px',
        color: palette.knollBurgundy[500],
        textDecoration: 'none',
        transition: 'color .250s',
        '@media (pointer: fine)': {
          '&:hover': {
            filter: 'brightness(92%)'
          }
        }
      }
    }
  }
};

export const overrides = {
  ...progress,
  ...variants,
  ...keyValuePairs,
  ...common,
  ...layout,
  ...body,
  ...topNavigation,
  ...footerNavigation,
  ...leftPanel,
  ...prompt,
  ...popOuts,
  ...responseAtoms,
  ...list,
  ...visual
};
