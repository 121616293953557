import React from 'react';
import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import { ComponentBase } from '../../models/models';
// import different modal components
import SimpleModal, { Props as SimpleModalProps } from '../Modals/SimpleModal';

// Using discriminating unions in case of future modals
type ModalProps = 
  | SimpleModalProps;

interface ModalMapInterface {
  [key: string]: React.FC<ModalProps>
}

export interface Props extends ComponentBase {
  /**
   * Link text.
   */
  label: string;
  /**
   * Text before the link.
   */
  preface?: string;
  /**
   * text alignment.
   */
  textAlign?: 'left' | 'center' | 'right';
  /**
   * Modal that is opened by this link.
   */
  modalTriggered: string;
  /**
   * Content displayed by modal.
   */
  modalContent: {
    headline?: string;
    body?: string;
    closeType?: 'button' | 'icon';
  };
}

const ModalMap: ModalMapInterface = {
  SimpleModal
};

const ModalLink: React.FC<Props> = (
  {
    label,
    preface,
    modalTriggered,
    modalContent,
    textAlign = 'left'
  }
) => {
  const [ isModalOpen, setIsModalOpen ] = React.useState<boolean>(false);

  // @ts-ignore Mui bug with spreading theme variables.
  const classes = useStyles({ textAlign });


  const openModal = () => {
    // open modalTriggered component and pass modalContent
    setIsModalOpen(true);

    if (modalTriggered === 'TestModal') {
    // eslint-disable-next-line no-console
      console.log('test-click');
    }
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const ModalComponent = ModalMap[modalTriggered] || null;

  return (
    <>
      <p className={classes.root} data-testid="ModalLink">
        {!!preface && preface}
        <button
          type="button"
          className={classes.link}
          onClick={() => openModal()}
        >
          {label}
        </button>
      </p>
      {!!ModalComponent &&
        React.createElement(ModalComponent, {
          headline: modalContent.headline,
          body: modalContent.body,
          open: isModalOpen,
          closeType: modalContent.closeType,
          handleClose
        })}
    </>
  );
};

const useStyles = makeStyles<Theme, Props>(
  (theme) =>
    createStyles({
      root: {
        ...theme.typography.body1,
        width: '100%',
        textAlign: ({ textAlign }) => textAlign,
        color: theme.palette.primary.main
      },
      link: {
        background: 'transparent',
        border: 'none',
        color: theme.palette.secondary.main,
        cursor: 'pointer',
        fontSize: 'unset',
        display: 'inline-block',
        margin: 0,
        outline: 'none',
        padding: 0,
        textDecoration: 'underline'
      }
    }),
  { name: 'Mui-ModalLink' }
);

export default ModalLink;