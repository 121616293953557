import React, { Reducer } from 'react';
import { ThemeProvider, createMuiTheme } from '@material-ui/core';
import { ExperienceTheme } from './themes/ThemeTypes';
import {
  yourcase,
  mrreset,
  defaultTheme,
  kele,
  drs,
  ylfDemo,
  knoll,
  vam
} from './themes';

type ExperienceThemes =
  | 'default'
  | 'yourcase'
  | 'mrreset'
  | 'kele'
  | 'drs'
  | 'ylfDemo'
  | 'knoll'
  | 'vam';

const themeMap: Record<ExperienceThemes, ExperienceTheme> = {
  default: defaultTheme,
  yourcase,
  mrreset,
  kele,
  drs,
  ylfDemo,
  knoll,
  vam
};

type ActionExperienceTheme =
  | { type: 'CREATE_THEME'; payload: ExperienceTheme } // Will be used when theme is sent via init call
  | { type: 'MAP_TO_THEME'; payload: string }; // Currently used to map a theme name to a theme living in SDK

type Dispatch = (action: ActionExperienceTheme) => void;
type ActionTriggers = {
  createTheme: (payload: ExperienceTheme) => void;
  mapToTheme: (payload: string) => void;
};

interface ContextValues extends ActionTriggers {
  theme: ExperienceTheme;
}

// Action Creators
const actionCreateTheme = (dispatch: Dispatch, payload: ExperienceTheme) => {
  const action = { type: 'CREATE_THEME', payload };
  dispatch(action as ActionExperienceTheme);
};
const actionMapToTheme = (dispatch: Dispatch, payload: string) => {
  const action = { type: 'MAP_TO_THEME', payload };
  dispatch(action as ActionExperienceTheme);
};

export const ExperienceThemeContext = React.createContext<
  ContextValues | undefined
>(undefined);

const experienceThemeReducer = (
  state: ExperienceTheme,
  action: ActionExperienceTheme
) => {
  switch (action.type) {
    case 'CREATE_THEME': {
      return {
        ...state,
        ...action.payload
      };
    }

    case 'MAP_TO_THEME': {
      if (action.payload in themeMap) {
        return {
          ...state,
          ...themeMap[action.payload as ExperienceThemes]
        };
      }
      return {
        ...state
      };
    }

    default:
      throw new Error('Action type not recognized.');
  }
};

export const ExperienceThemeProvider: React.FC = ({ children }) => {
  const [state, dispatch] = React.useReducer<
    Reducer<ExperienceTheme, ActionExperienceTheme>
  >(experienceThemeReducer, defaultTheme);

  // Triggers

  const createTheme = React.useCallback(
    (themeObject: ExperienceTheme) => {
      actionCreateTheme(dispatch, themeObject);
    },
    [dispatch]
  );

  const mapToTheme = React.useCallback(
    (themeKey: string) => {
      actionMapToTheme(dispatch, themeKey);
    },
    [dispatch]
  );

  return (
    <ExperienceThemeContext.Provider
      value={{
        theme: state,
        createTheme,
        mapToTheme
      }}
    >
      <ThemeProvider theme={createMuiTheme(state.styles)}>
        {children}
      </ThemeProvider>
    </ExperienceThemeContext.Provider>
  );
};

export const useExperienceThemeContext = () => {
  const context = React.useContext(ExperienceThemeContext);
  if (context === undefined) {
    throw new Error(
      'useExperienceThemeContext must be used within ExperienceThemeProvider.'
    );
  }
  return context;
};
