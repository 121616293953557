export const vamBlue = {
  900: '#213052',
  500: '#2B9FDE',
  100: '#69bae6'
};

export const vamGray = {
  main: '#213052',
  body: '#171717',
  light: '#F6F5F5',
  disabled: '#D7D7D7'
};

export const success = {
  500: '#439B18',
  100: '#DEF5D2',
  50: '#F4FEEF'
};

export const error = {
  500: '#FF3535',
  100: '#FFD7D7',
  50: '#FFF5F5'
};

export const warning = {
  500: '#FF9900',
  100: '#FFF3B3',
  50: '#FFFAE0'
};

export const info = {
  500: '#3368FF',
  50: '#F5F8FF'
};

export const white = '#FFFFFF';

export const black = '#000';
