import React from 'react';
import parse from 'html-react-parser';
import { ComponentBase } from '../../../models/models';
import { useRadioDispatch } from '../../Core/radioContext';
import TypeInBase from '../../atoms/TypeInBase';
import { useDebounce } from '../../../helpers/useDebounce';


export interface Props extends ComponentBase {
  /**
   * Whether the user is required to fill in the textfield.
   */
  isRequired?: boolean;
  /**
   * Label displayed on top of Textfield.
   */
  label: string;
  /**
   * Unique ID from Json.
   */
  uuid: string;
  /**
   * Placeholder on Textfield.
   */
  placeholder?: string;
  /**
   * SVG element use as an icon when input is valid
   */
  validIcon?: string;
  /**
   * SVG element use as an icon when input is not valid
   */
  errorIcon?: string;
}

const TypeInPassword: React.FC<Props> = ({
  isRequired = true,
  uuid,
  placeholder,
  label,
  validIcon,
  errorIcon
}) => {
  const { registerResponseComponent, logResponse } = useRadioDispatch();


  const [value, setValue] = React.useState<string>('');
  const [error, setError] = React.useState<boolean>(false);
  const [endAdornment, setEndAdornment] = React.useState<string>();
  const errorMessage = 'Invalid password.';

  // Register component up in context.
  React.useEffect(() => {
    registerResponseComponent({
      componentType: 'input',
      componentId: uuid,
      value: null,
      isRequired
    });
  }, [registerResponseComponent, uuid, isRequired]);

  const validatePassword = (input: string) => {
    return isRequired ? input.trim().length !== 0 : true;
  };

  const debounce = useDebounce((id, input) =>
    logResponse({ componentId: id, value: input })
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setValue(e.target.value);

    const input = validatePassword(e.target.value) ? e.target.value : null;
    // debounce logResponse dispatch with default 350ms delay
    debounce(uuid, input);
  };

  const handleOnBlur = () => {
    const isValid = validatePassword(value);

    setError(!isValid);
    if(validIcon && isValid) {
      setEndAdornment(validIcon);
    }
    if (errorIcon && !isValid) {
      setEndAdornment(errorIcon);
    }
  };

  return (
    <TypeInBase
      isRequired={isRequired}
      uuid={uuid}
      label={label}
      placeholder={placeholder}
      maxCharLength={30}
      value={value}
      handleChange={handleChange}
      handleOnBlur={handleOnBlur}
      errorMessage={error ? errorMessage : null}
      error={error}
      type='password'
      endAdornment={endAdornment ? (parse(endAdornment) as JSX.Element) : undefined}
    />
  );
};

export default TypeInPassword;
