import React from 'react';

const REQUIRED_CONFIG_PROPS = [
  'radioApiUrl',
  'radioApiKey',
  'radioApiDomain',
  'radioApiApp',
  'logglyCustomerToken',
  'segmentWriteKey'
];

type ConfigState = Record<string, string | boolean>;

type Props = {
  config: Record<string, string | boolean>;
  reqCheck?: boolean;
  children: React.ReactNode;
};

const defaultConfigState: ConfigState = {
  sdkEnv: 'production',
  domainRoot: window.location.origin,
  sdkUseBrowserNavigation: false
};

const ConfigSetter: React.FC<Props> = ({
  config = {},
  reqCheck = true,
  children
}) => {
  const configState = {
    ...defaultConfigState,
    ...(window.RadioSDK?.config ? window.RadioSDK.config : {}),
    ...config
  };

  const missingProps = REQUIRED_CONFIG_PROPS.filter(
    (prop) => !Object.keys(configState).includes(prop)
  );

  if (missingProps.length > 0 && reqCheck) {
    const propList = missingProps.join(', ');
    const message = `Missing required config properties: ${propList}. \n\nPass properties in via <RadioSDK />'s config prop or on window.RadioSDK.config.`;
    // eslint-disable-next-line no-console
    console.error(message);
    return (
      <div>
        Unable to bootstrap &#60;RadioSDK /&#62;, see developer console.
      </div>
    );
  }

  /**
   * Why localStorage instead of React.context?
   * RadioSDK has some non-component helpers that need access to the config
   * object and this was the best option while quickly finishing up
   * multi-tenant without the need to pass a bunch of props down.
   *
   * A helper function is available in src/helpers/config.ts for easy
   * value retrieval.
   */
  localStorage.setItem('RadioSDK:config', JSON.stringify(configState));

  return <div>{children}</div>;
};

export default ConfigSetter;
