import * as brand from './colors';
import { typography } from './typography';
import { shadows } from './shadows';
import { overrides } from './overrides';

export const styles = {
  palette: {
    primary: {
      main: brand.mrNavy[700]
    },
    secondary: {
      main: brand.mrGreen[500] // tossup between this or mrGreen
    },
    error: {
      main: brand.mrOrange[500]
    }
  },
  typography,
  shadows,
  overrides
};
