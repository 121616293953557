import React from 'react';
import {
  Button,
  makeStyles,
  createStyles,
} from '@material-ui/core';

import { ComponentBase } from '../../models/models';
import { useRadioDispatch, useRadioState } from '../Core/radioContext';

export interface SingleLongButtonNavigationProps extends ComponentBase {
  /**
   * 1–3 word text.
   */
  headline: string;
  /**
   * A unique id for the response.
   */
  uuid: string;
}

const SingleLongButtonNavigation: React.FC<SingleLongButtonNavigationProps> = ({
  headline,
  uuid,
}) => {
  const { logResponseAndComplete } = useRadioDispatch();
  const radioState = useRadioState();
  const classes = useStyles();

  const handleClick = () => {
    logResponseAndComplete({
      componentId: uuid,
    });
  };

  return (
    <div className={classes.root}>
      <div className={classes.widthConstraint}>
        <Button
          variant="contained"
          classes={{
            root: classes.button,
            label: classes.buttonLabel,
            disabled: classes.buttonDisabled
          }}
          fullWidth
          disableRipple
          onClick={handleClick}
          disabled={!radioState.canComplete}
        >
          {headline}
        </Button>
      </div>
    </div>
  );
};

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        display: 'flex',
        justifyContent: 'center',
        pointerEvents: 'all',
      },
      widthConstraint: {
        height: '100%',
        maxWidth: '800px',
        width: '100%',
      },
      button: {
        background: theme.palette.secondary.main,
        padding: theme.spacing(2),
        '&:hover': {
          background: theme.palette.secondary.dark,
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            backgroundColor: theme.palette.secondary.main,
          },
        },
        '&:enabled':{
          color: theme.palette.secondary.contrastText,
        }
      },
      buttonLabel: {},
      buttonDisabled: {}
    }),
  { name: 'Mui-SingleLongButtonNavigation' }
);

export default SingleLongButtonNavigation;
