import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { format, formatISO, isValid, isAfter, isBefore, parse } from 'date-fns';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { ComponentBase } from '../../../models/models';
import { useRadioDispatch, useRadioState } from '../../Core/radioContext';
import { useDebounce } from '../../../helpers/useDebounce';

export interface Props extends ComponentBase {
  /**
   * Whether the user is required to fill in the DatePicker.
   */
  isRequired?: boolean;
  /**
   * Disable future dates
   */
  disableFuture?: boolean;
  /**
   * Disable past dates
   */
  disablePast?: boolean;
  /**
    * Label displayed on top of DatePicker.
    */
  label: string;
  /**
    * Unique ID from Json.
    */
  uuid: string;
  /**
    * Placeholder on DatePicker.
    */
  placeholder?: string;
  /**
    * defaultValue on DatePicker.
    */
  defaultValue?: string;
  /**
    * maximum date to allow the user to pick
    */
  maxDate?: string;
  /**
    * minimum date to allow the user to pick
    */
  minDate?: string;
  /**
    * maximum number of days in the future to allow the user to pick
    */
  maxRelativeDate?: number;
  /**
     * maximum number of days in the past to allow the user to pick
     */
  minRelativeDate?: number;
}

const DatePicker: React.FC<Props> = ({
  isRequired = true,
  uuid,
  placeholder = 'MM/DD/YYYY',
  label,
  defaultValue,
  disableFuture = false,
  disablePast = false,
  maxDate,
  minDate,
  maxRelativeDate,
  minRelativeDate
}) => {
  const classes = useStyles();
  const isYearFirstFormat = (date: string) => {
    if(date[4] === '-') return true;
    return false;
  };
  const getInitDate = React.useCallback((date: string): Date | null => {
    if(date && isValid(new Date(date))) {
      return isYearFirstFormat(date) ? parse(date, 'yyyy-MM-dd', new Date()) : new Date(date);
    } 
    return null;
  }, []);

  const { registerResponseComponent, logResponse } = useRadioDispatch();
  const radioState = useRadioState();

  const initValue = radioState.apiData.responses?.[uuid] || defaultValue || '';

  const [value, setValue] = React.useState<Date | null>(getInitDate(initValue));

  const getRelativeDate = (days: number, operation: 'add' | 'subtract' = 'add' ) => {
    const newDate = new Date();
    if(operation === 'add') { 
      newDate.setDate(newDate.getDate() + days);
    } else {
      newDate.setDate(newDate.getDate() - days);
    }
    newDate.setHours(0, 0, 0, 0);
    return newDate;
  };

  const validateDate = (date: Date) => {
    let validity = isValid(date);
    if(maxDate && validity) {
      validity = isBefore(date, new Date(maxDate)) || format(date, 'MM/dd/yyyy') === format(new Date(maxDate), 'MM/dd/yyyy');
    }
    if(minDate && validity) {
      validity = isAfter(date, new Date(minDate)) || format(date, 'MM/dd/yyyy') === format(new Date(minDate), 'MM/dd/yyyy');
    }
    if(maxRelativeDate && validity) {
      validity = isBefore(date, getRelativeDate(maxRelativeDate + 1));
    }
    if(minRelativeDate && validity) {
      validity = isAfter(date, getRelativeDate(minRelativeDate + 1, 'subtract'));
    }
    return validity;
  };
  
  // Register component up in context.
  React.useEffect(() => {
    registerResponseComponent({
      componentType: 'input',
      componentId: uuid,
      value: getInitDate(initValue) ? formatISO(getInitDate(initValue) as Date) : null,
      isRequired
    });
    registerResponseComponent({
      componentType: 'input',
      componentId: `${uuid}_ff`,
      value: getInitDate(initValue) ? format(getInitDate(initValue) as Date, 'yyyy-MM-dd') : null,
      isRequired
    });
  }, [registerResponseComponent, uuid, isRequired, initValue, getInitDate]);

  const debounce = useDebounce((id, input) => {
    logResponse({
      componentId: id,
      value: input ? formatISO(new Date(input)) : null
    });
    logResponse({
      componentId: `${id}_ff`,
      value: input ? format(new Date(input), 'yyyy-MM-dd') : null
    });
  }
  );

  const handleChange = (date: Date | null): void => {
    setValue(date);
    const input = date && validateDate(date) ? date : null;
    // debounce logResponse dispatch with default 350ms delay
    debounce(uuid, input);
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        maxDate={maxDate || (maxRelativeDate && getRelativeDate(maxRelativeDate))}
        minDate={minDate || (minRelativeDate && getRelativeDate(minRelativeDate, 'subtract'))}
        disablePast={disablePast}
        disableFuture={disableFuture}
        fullWidth
        placeholder={placeholder}
        InputLabelProps={{
          shrink: true,
          className: classes.label
        }}
        InputProps={{
          className: classes.input,
          classes:{
            underline: classes.underline,
            error: classes.error
          }
        }}
        FormHelperTextProps={{
          className: classes.helperText
        }}
        disableToolbar
        variant="inline"
        format="MM/dd/yyyy"
        margin="normal"
        id="date-picker-inline"
        label={label}
        value={value}
        onChange={handleChange}
        invalidDateMessage='Invalid Date'
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
      />
    </MuiPickersUtilsProvider>
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    label: {},
    input: {},
    underline: {},
    helperText: {},
    error: {
    }
  }), { name: 'Mui-DatePicker' }
);


export default DatePicker;
