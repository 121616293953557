const ENCODE: Record<string, string> = {
  '/': '_',
  '+': '-',
  '=': '.'
};

const DECODE: Record<string, string> = {
  _: '/',
  '-': '+',
  '.': '='
};

export const encodeUrlSafeBase64 = (
  str: string,
  trimmed: boolean = true
): string => {
  const encoded = btoa(str).replace(/[+/=]/g, (m) => ENCODE[m]);
  return trimmed ? encoded.replace(/[.=]{1,2}$/, '') : encoded;
};

export const decodeUrlSafeBase64 = (str: string) => {
  return atob(str.replace(/[-_.]/g, (m) => DECODE[m]));
};
