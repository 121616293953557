import React from "react";

export const DefaultCloseIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 19L12 12M12 12L19 5M12 12L5 5M12 12L19 19"
      stroke="#A3A3A3"
      strokeWidth="2"
    />
  </svg>
);