import { styles } from '../kele/styles';
import { ExperienceTheme } from '../ThemeTypes';

const strings = {
  brand: 'Your Law Firm.',
  brandUrl: 'https://qa.yourcase.com',
  errorToast: {
    default: {
      message: "Looks like we're having a little Internet turbulence.",
      buttonLabel: 'Try Again'
    },
    deadLock: {
      message:
        "Sorry, we're unable to process your request right now. Please try again later.",
      buttonLabel: 'YourCase.com'
    }
  }
};

export const ylfDemo: ExperienceTheme = {
  strings,
  styles
};
