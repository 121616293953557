import Cookies from 'universal-cookie';
import { encodeUrlSafeBase64 } from './urlSafeBase64';
import { getConfig } from './config';

type StepJsonHeader = {
  id: string;
  name: string;
  nodeTree: Object;
  protected?: boolean;
};

export const isAuthRequired = (stepJsonHeader: StepJsonHeader) => {
  const hasProtectedKey = Object.keys(stepJsonHeader).includes('protected');
  if (hasProtectedKey && stepJsonHeader.protected === true) return true;

  return false;
};

export const getIdToken = () => {
  const cookies = new Cookies();
  const { cognitoAppClientId } = getConfig();

  // Check for auth cookies, if missing not auth'ed.
  // A "sub" Cognito's internal user ID.
  const prefix = 'CognitoIdentityServiceProvider';
  const lastSub = cookies.get(`${prefix}.${cognitoAppClientId}.LastAuthUser`);
  const idToken = cookies.get(
    `${prefix}.${cognitoAppClientId}.${lastSub}.idToken`
  );
  if (!lastSub || !idToken) return false;

  // Verify JWT structure (middle is user info),
  // confirm expiration date is not in the past.
  const userDetails = JSON.parse(atob(idToken.split('.')[1]));
  if (Math.floor(Date.now() / 1000) > userDetails.exp) return false;

  // Is authenticated. 🎉
  return idToken;
};

export const isAuthenticated = () => {
  return !!getIdToken();
};

const buildQueryState = () => {
  const config = getConfig();
  const state = {
    returnUrl: config.cognitoReturnUrl,
    cookieDomain: config.cognitoCookieDomain,
    awsRegion: config.cognitoAwsRegion,
    awsUserPoolId: config.cognitoUserPoolId,
    awsUserPoolWebClientId: config.cognitoAppClientId,
    iat: Date.now()
  };

  return encodeUrlSafeBase64(JSON.stringify(state));
};

export const getSignInUrl = () => {
  const { cognitoAuthSpaBaseUrl } = getConfig();
  const qs = buildQueryState();
  return `${cognitoAuthSpaBaseUrl}/signin?qs=${qs}`;
};

export const handleAuthCheck = (stepJsonHeader: StepJsonHeader) => {
  return new Promise((resolve, reject) => {
    if (isAuthRequired(stepJsonHeader) && !isAuthenticated()) {
      window.location.href = getSignInUrl();
      reject();
    }

    resolve(true);
  });
};
