import React from 'react';
import parse from 'html-react-parser';
import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import { ComponentBase } from '../../models/models';


export interface Props extends ComponentBase {
  /**
   * Description text.
   */
  description: string;
  /**
   * Link label text.
   */
  linkLabel?: string;
  /**
   * Link URL.
   */
  link?: string;
  /**
   * text alignment.
   */
  textAlign?: 'left' | 'center' | 'right';
  /**
   * Part of description that will be hidden under read more type of button.
   */
  expandableText?: string;
  /**
   * Label for button that will show and hide long text.
   */
  showHideTextLabel?: {
    show: string;
    hide: string;
  };
}

const Description: React.FC<Props> = (props) => {
  const {
    description,
    link,
    linkLabel,
    textAlign = 'left',
    expandableText,
    showHideTextLabel
  } = props;

  const [showExpandableText, setShowExpandableText] = React.useState<boolean>(
    false
  );
  // @ts-ignore Mui bug with spreading theme variables.
  const classes = useStyles({ textAlign });

  const handleShowExpandableText = () => {
    setShowExpandableText(!showExpandableText);
  };

  return (
    <>
      <p className={classes.root}>
        {parse(decodeURIComponent(description))}
        {showExpandableText && expandableText && (
          <span aria-labelledby="read-more-button">
            {parse(expandableText)}
          </span>
        )}
        {link && linkLabel && (
          <a
            href={link}
            className={classes.link}
            target="_blank"
            rel="noopener noreferrer"
          >
            {linkLabel}
          </a>
        )}
      </p>
      {expandableText && (
        <button
          type="button"
          id="read-more-button"
          className={classes.unstyledButton}
          onClick={handleShowExpandableText}
          aria-expanded={showExpandableText}
        >
          {showHideTextLabel &&
            (showExpandableText
              ? showHideTextLabel.hide
              : showHideTextLabel.show)}
        </button>
      )}
    </>
  );
};

const useStyles = makeStyles<Theme, Props>(
  (theme) =>
    createStyles({
      root: {
        ...theme.typography.body1,
        width: '100%',
        textAlign: ({ textAlign }) => textAlign,
        color: theme.palette.primary.main
      },
      link: {
        color: theme.palette.secondary.main,
        cursor: 'pointer',
        marginLeft: '.5em'
      },
      unstyledButton: {
        // remove default styles
        border: 'none',
        padding: '0 0 16px',
        cursor: 'pointer',
        outline: 'inherit',
        backgroundColor: 'inherit',
        // actual styles
        color: theme.palette.secondary.main,
        fontSize: theme.typography.body1.fontSize
      }
    }),
  { name: 'Mui-Description' }
);

export default Description;