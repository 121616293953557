import React from 'react';
import parse from 'html-react-parser';
import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import { ComponentBase } from '../../models/models';

export interface Props extends ComponentBase {
  /**
   * Headline text.
   */
  headline: string;
  /**
   * text alignment.
   */
  textAlign?: 'left' | 'center' | 'right';
}

const Headline: React.FC<Props> = (props) => {
  const {
    headline,
    textAlign = 'left'
  } = props;
  // @ts-ignore Mui bug with spreading theme variables.
  const classes = useStyles({textAlign});
  return (
    <h1 className={classes.root}>{parse(decodeURIComponent(headline))}</h1>
  );
};

const useStyles = makeStyles<Theme, Props>(
  
  (theme) =>
    createStyles({
      root: {
        ...theme.typography.h1,
        fontSize: '40px',
        width: '100%',
        textAlign: ({  textAlign  }) => textAlign
      }
    }),
 
  { name: 'Mui-Headline' }
);

export default Headline;