import React from 'react';
import TextField from '@material-ui/core/TextField';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { InputBaseComponentProps } from '@material-ui/core';
import { ComponentBase } from '../../models/models';

export interface Props extends ComponentBase {
  /**
   * If true typeIn will be disabled.
   */
  disabled?: boolean;
  /**
   * Whether the user is required to fill in the textfield.
   */
  isRequired?: boolean;
  /**
   * Label displayed on top of Textfield.
   */
  label: string;
  /**
   * Unique ID from Json.
   */
  uuid: string;
  /**
   * Placeholder on Textfield.
   */
  placeholder?: string;
  /**
   * value on Textfield.
   */
  value?: string;
  /**
   * Error state.
   */
  error?: boolean;
  /**
   * error message on Textfield.
   */
  errorMessage?: string | null;
  /**
   * maximum number of characters on Textfield.
   */
  maxCharLength?: number;
  /**
   * textfield variant.
   */
  textFieldVariant?: 'filled' | 'standard' | any;
  /**
   * multiline state of textfield.
   */
  multiline?: boolean;
  /**
   * number of rows of textfield.
   */
  rows?: number;
  /**
   * max number of rows of textfield.
   */
  rowsMax?: number;
  /**
   * Type of the input element. It should be a valid HTML5 input type.
   */
  type?: string;
  /**
   * function triggered to handle a change.
   */
  handleChange: (ev: React.ChangeEvent<HTMLInputElement>) => void;
  /**
   * function triggered to handle a change.
   */
  handleOnBlur?: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  /**
   * optional end adornment element.
   */
  endAdornment?: JSX.Element;
  /**
   * optional input masking component.
   */
  inputMaskComponent?: React.FunctionComponent<InputBaseComponentProps>;
}


const TypeInBase: React.FC<Props> = (
  {isRequired = true, disabled = false,
    uuid, placeholder, label, value,
    error, errorMessage, maxCharLength,
    handleChange, handleOnBlur, textFieldVariant,
    multiline, rows, endAdornment, inputMaskComponent, rowsMax, type = 'text',
  }) => {

  const classes = useStyles();


  return (
    <TextField
      disabled={disabled}
      className={classes.root}
      required={isRequired}
      id={uuid}
      name={label}
      label={label}
      placeholder={placeholder}
      fullWidth
      InputLabelProps={{ 
        shrink: true,
        className: classes.label
      }}
      inputProps={{ 
        maxLength: maxCharLength}}
      // eslint-disable-next-line react/jsx-no-duplicate-props
      InputProps={{
        className: classes.input,
        classes:{
          ...(textFieldVariant === 'outlined' ? (
            {}
          ): (
            { underline: classes.underline}
          ))
        },
        endAdornment: endAdornment || null,
        inputComponent: inputMaskComponent || undefined
      }}
      FormHelperTextProps={{
        className: classes.helperText
      }}
      variant={textFieldVariant}
      value={value}
      onChange={handleChange}
      onBlur={handleOnBlur}
      helperText={error ? errorMessage : null}
      error={error}
      multiline={multiline}
      rows={rows}
      rowsMax={rowsMax}
      type={type}
    />
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
    label: {},
    input: {},
    underline: {},
    helperText: {},
  }), { name: 'Mui-TypeInBase' }
);

export default TypeInBase;