import { makeVariant } from '../../../utils/createTypography';

export const fontStack = {
  raleway: '"Raleway", Helvetica, Arial, sans-serif'
};

export const typographyVariants = {
  h1: makeVariant({
    fontFamily: fontStack.raleway,
    fontSize: '28px',
    fontWeight: 500,
    lineHeight: '130%'
  }),
  h2: makeVariant({
    fontFamily: fontStack.raleway,
    fontSize: '28px',
    fontWeight: 400,
    lineHeight: '130%'
  }),
  h3: makeVariant({
    fontFamily: fontStack.raleway,
    fontSize: '24px',
    fontWeight: 400,
    lineHeight: '130%'
  }),
  h4: makeVariant({
    fontFamily: fontStack.raleway,
    fontSize: '20px',
    fontWeight: 400,
    lineHeight: '130%'
  })
};

// Currently we dont have all weights but eventually we will
export const customTypographyVariants = {
  sh1: makeVariant({
    fontFamily: fontStack.raleway,
    fontSize: '28px',
    fontWeight: 500,
    lineHeight: '130%'
  }),
  sh2: makeVariant({
    fontFamily: fontStack.raleway,
    fontSize: '20px',
    fontWeight: 500,
    lineHeight: '130%'
  }),
  bodyLarge: makeVariant({
    fontFamily: fontStack.raleway,
    fontSize: '1rem',
    fontWeight: 400,
    lineHeight: '130%'
  }),
  bodyLargeMedium: makeVariant({
    fontFamily: fontStack.raleway,
    fontSize: '1rem',
    fontWeight: 500,
    lineHeight: '130%'
  }),
  bodyRegular: makeVariant({
    fontFamily: fontStack.raleway,
    fontSize: '.875rem',
    fontWeight: 400,
    lineHeight: '130%'
  }),
  bodyRegularMedium: makeVariant({
    fontFamily: fontStack.raleway,
    fontSize: '.875rem',
    fontWeight: 500,
    lineHeight: '130%'
  }),
  labelLarge: makeVariant({
    fontFamily: fontStack.raleway,
    fontSize: '1rem',
    fontWeight: 500,
    lineHeight: '120%'
  }),
  labelRegular: makeVariant({
    fontFamily: fontStack.raleway,
    fontSize: '.875rem',
    fontWeight: 500,
    lineHeight: '120%'
  }),
  labelSmall: makeVariant({
    fontFamily: fontStack.raleway,
    fontSize: '.75rem',
    fontWeight: 500,
    lineHeight: '120%'
  }),
  labelSmallUppercase: makeVariant({
    fontFamily: fontStack.raleway,
    fontSize: '.75rem',
    fontWeight: 500,
    lineHeight: '120%'
  }),
  labelTiny: makeVariant({
    fontFamily: fontStack.raleway,
    fontSize: '.625rem',
    fontWeight: 500,
    lineHeight: '120%'
  }),
  textLink: makeVariant({
    fontFamily: fontStack.raleway,
    fontSize: '.875rem',
    fontWeight: 500,
    lineHeight: '120%'
  })
};

export const typography = {
  fontFamily: fontStack.raleway, // the default font
  ...typographyVariants
};
