import React from 'react';
import Cookies from 'universal-cookie';
import psl from 'psl';
import { Helmet } from 'react-helmet-async';
import { nanoid } from 'nanoid';

import { ComponentGeneric } from '../../models/models';
import segment from '../../helpers/segmentHelper';
import loggly from '../../helpers/loggly';
import MakeRenderer from '../../renderer/RenderNodeTree';

import * as ComponentLibrary from '../ComponentLibrary';
import Carousel from '../Carousel/Carousel';

import { useExperienceThemeContext } from '../../styles/experienceThemeContext';
import { useRadioDispatch, useRadioState } from './radioContext';
import { useNoteContext } from '../Notifications/noteContext';
import {
  initializeRadio,
  startRadio,
  reentryRadio,
  completeRadio
} from '../../services/radioApi';
import ApiErrorToast from '../Notifications/ApiErrorToast';
import { getUrlParams } from '../../helpers/params';
import { getConfig } from '../../helpers/config';
import { handleAuthCheck, getSignInUrl } from '../../helpers/cognitoAuth';

import { useNavigationContext } from './navigationContext';

import NavDebugger from './NavDebugger';

// eventually this Loading screen will come from the init call
import { DefaultLoadingScreenJSON } from '../../styles/DefaultLoadingScreenJSON';

const FullComponentMap = {
  ...ComponentLibrary,
  Carousel
};

const Renderer = MakeRenderer(FullComponentMap);

// Prevents RenderNodeTree from re-rendering with identical JSON.
const RadioRender: React.FC<ComponentGeneric> = React.memo(({ nodeTree }) => {
  return <>{Renderer(nodeTree)}</>;
});

const RadioTuner: React.FC = () => {
  const config = getConfig();
  const SDK_ENV = config.sdkEnv;
  const REENTRY_PARAM = config.cognitoReentryParam;
  const USE_COGNITO_AUTH = config.useCognitoAuth === true;

  const radioState = useRadioState();
  const {
    beginStep,
    networkErrorReset,
    loadingStep,
    showLoadingScreen,
    resetLoadingScreen
  } = useRadioDispatch();
  const { pushToHistory } = useNavigationContext();
  const { dispatch: noteDispatch } = useNoteContext();
  const { createTheme, mapToTheme } = useExperienceThemeContext();

  const [segmentWriteKey, setSegmentWriteKey] = React.useState<string>();
  // Safeguard against multiple /complete calls.
  const [completeIsRunning, setCompleteIsRunning] = React.useState<boolean>(
    false
  );
  /* eslint-disable @typescript-eslint/indent */
  const [loadingScreen, setLoadingScreen] = React.useState<
    Record<string, any>
  >();
  /* eslint-enable */

  React.useEffect(() => {
    const cookies = new Cookies();

    const bootRadio = async () => {
      // On init loading is true without delay
      loadingStep();

      const urlParams = getUrlParams();
      const now = new Date();
      const cookieOpts = {
        expires: new Date(now.getFullYear() + 1, now.getMonth(), now.getDate()),
        domain:
          window.location.hostname === 'localhost'
            ? 'localhost'
            : `.${psl.get(window.location.hostname)}`
      };

      const presetProfileUuid =
        urlParams.hmpi || cookies.get('tbhm_profile_uuid') || null;
      let guid = urlParams.hmgi || cookies.get('tbhm_guid') || null;

      if (!guid) {
        guid = nanoid();
        cookies.set('tbhm_guid', guid, cookieOpts);
      }

      const initReq = { traceId: radioState.traceId };

      try {
        const initRes = await initializeRadio(initReq);

        const startReq = {
          traceId: radioState.traceId,
          requestData: {
            ...(presetProfileUuid ? { profileUuid: presetProfileUuid } : {}),
            ...(guid ? { guid } : {}),
            data: { payload: { ...urlParams } }
          }
        };

        if (
          initRes.responseData.loadingScreen &&
          Object.keys(initRes.responseData.loadingScreen).length > 0
        ) {
          setLoadingScreen({ ...initRes.responseData.loadingScreen });
        } else {
          setLoadingScreen({ ...DefaultLoadingScreenJSON });
        }

        // Future code: when we get theme.content (tentative prop name) from init call
        if (initRes.responseData.theme.name) {
          mapToTheme(initRes.responseData.theme.name);
        } else if (initRes.responseData.theme.content) {
          createTheme(initRes.responseData.theme.content);
        }

        // Only show loading screen after theme is loaded
        if (initRes.responseData.domain !== 'unitTest') {
          showLoadingScreen();
        }

        const useReentry =
          USE_COGNITO_AUTH && /true/i.test(urlParams[REENTRY_PARAM] || '');
        const svcCall = useReentry ? reentryRadio : startRadio;
        const startRes = await svcCall(startReq);

        const data = startRes.responseData;
        const apiData = data.uiData ? data.uiData : {};
        if (!data.step) throw Error('No stepJson in boot response');

        // Auth check.
        await handleAuthCheck(data.step);

        beginStep({
          profileUuid: data.profileUuid,
          flowId: data.flowId,
          stepId: data.stepId,
          stepUuid: data.stepUuid,
          nodeTree: data.step.nodeTree,
          apiData,
          isLoading: false,
          hasLoadingScreen: false
        });

        // Do the Segment.
        setSegmentWriteKey(initRes.responseData.segmentWriteKey);
        segment.identify(data.profileUuid, {
          app: 'consumer', // apiApp
          domain: 'yourcase', // apiDomain
          ...urlParams
        });
        segment.page(data.stepId);

        // Set browser title and URL
        pushToHistory(
          data.experienceProcessId,
          data.flowId,
          data.stepId,
          data.stepUuid,
          data.step.name
        );

        // Consume profileUuid cookie.
        cookies.remove('tbhm_profile_uuid', {
          path: '/',
          domain: cookieOpts.domain
        });
      } catch (error: any) {
        // Decode errors
        const body = error.status ? await error.json() : { error: '' };
        console.log(error); // eslint-disable-line

        loggly.push({
          message: `API call failed in <RadioTuner /> → bootRadio() → ${body.error}`,
          userAgent: window.navigator.userAgent,
          windowLocation: window.location.href,
          fetch: {
            url: error.url,
            status: error.status,
            statusText: error.statusText,
            redirected: error.redirected
          }
        });

        const localAuthFail = error.toString() === 'Error: SDK_LOCAL_AUTH';
        if ((localAuthFail || error.status === 401) && USE_COGNITO_AUTH) {
          window.location.href = getSignInUrl();
          return;
        }

        noteDispatch({
          type: 'SHOW_API_ERROR_TOAST',
          payload: { deadlock: true }
        });
      }
    };

    bootRadio();
  }, [
    beginStep,
    noteDispatch,
    radioState.traceId,
    createTheme,
    mapToTheme,
    loadingStep,
    showLoadingScreen,
    pushToHistory
  ]);

  React.useEffect(() => {
    const fireComplete = async () => {
      setCompleteIsRunning(true);

      loadingStep();

      if (radioState.isLoadingScreenEnabled) {
        setTimeout(() => {
          showLoadingScreen();
        }, 750);
      }

      const useApiTranslators =
        radioState.apiTranslators && radioState.apiTranslators.length > 0
          ? { useApiTranslators: radioState.apiTranslators }
          : {};

      const compReq = {
        traceId: radioState.traceId,
        profileUuid: radioState.profileUuid,
        flowId: radioState.flowId,
        stepId: radioState.stepId,
        stepUuid: radioState.stepUuid,
        responses: [...radioState.responses],
        ...useApiTranslators,
        canComplete: radioState.canComplete
      };

      try {
        const completeRes = await completeRadio(compReq);

        noteDispatch({ type: 'HIDE_API_ERROR_TOAST_AND_RESET' });

        const data = completeRes.responseData;
        const apiData = data.uiData ? data.uiData : {};

        // reset isLoadingScreenEnabled to false before state is set in case disableNextLoadingScreen function is sent on JSON step
        resetLoadingScreen();

        segment.page(data.stepId);

        // Auth check.
        await handleAuthCheck(data.step);

        beginStep({
          profileUuid: data.profileUuid,
          flowId: data.flowId,
          stepId: data.stepId,
          stepUuid: data.stepUuid,
          nodeTree: data.step.nodeTree,
          apiData,
          isLoading: false,
          hasLoadingScreen: false,
          apiTranslators: [] // reset translators
        });

        // Set browser title and URL
        pushToHistory(
          data.experienceProcessId,
          data.flowId,
          data.stepId,
          data.stepUuid,
          data.step.name
        );
      } catch (error) {
        loggly.push({
          message: 'API call failed in <RadioTuner />, fireComplete()',
          userAgent: window.navigator.userAgent,
          windowLocation: window.location.href,
          fetch: {
            url: error.url,
            status: error.status,
            statusText: error.statusText,
            redirected: error.redirected
          },
          radioContext: { ...radioState }
        });

        networkErrorReset();
        noteDispatch({ type: 'SHOW_API_ERROR_TOAST' });
      } finally {
        // This is ugly but its the only way I could think of to unit test complete without having an infinite loop
        if (radioState.flowId !== 'testFlow') {
          setCompleteIsRunning(false);
        }
      }
    };

    if (radioState.stepComplete && !completeIsRunning) {
      fireComplete();
    }
  }, [
    radioState,
    completeIsRunning,
    networkErrorReset,
    beginStep,
    noteDispatch,
    loadingStep,
    showLoadingScreen,
    pushToHistory,
    resetLoadingScreen
  ]);

  return (
    <>
      <Helmet>
        {segmentWriteKey && (
          <script>{segment.getSnippet(segmentWriteKey, false)}</script>
        )}
      </Helmet>
      {(radioState.nodeTree ||
        (radioState.isLoading && radioState.hasLoadingScreen)) && (
        <RadioRender
          nodeTree={
            radioState.isLoading && radioState.hasLoadingScreen
              ? loadingScreen
              : radioState.nodeTree
          }
        />
      )}
      <ApiErrorToast />
      {SDK_ENV === 'development' && <NavDebugger />}
    </>
  );
};

export default RadioTuner;
