import * as brand from './colors';
import { typography } from './typography';
import { shadows } from './shadows';
import { overrides } from './overrides';

export const styles = {
  palette: {
    primary: {
      main: brand.knollGray.main
    },
    secondary: {
      main: brand.knollBurgundy[500]
    }
  },
  typography,
  shadows,
  overrides
};
