import React from 'react';
import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import { ComponentBase } from '../../models/models';
import Stepper, { Props as StepperProps } from '../Progress/Stepper';
import CardAtomWithImage, { Props as CardProps } from '../atoms/CardAtomWithImage';

export interface Props extends ComponentBase {
  /**
   * stepper props like orientation, icons, and stepProps.
   */
  stepperProps?: StepperProps;
  /**
   * card props like imgSrc, imageWidth, and top/bottom values
   */
  cardProps: CardProps[];
}

const CardWithImageList: React.FC<Props> = ({
  stepperProps, 
  cardProps
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {stepperProps && 
      (
        <Stepper {...stepperProps} />
      )}
      <div className={classes.cardListContainer}>
        {
          cardProps.map(card => {
            return(
              <CardAtomWithImage {...card} />
            );
          })
        }
      </div>
    </div>
  );
};

const useStyles = makeStyles<Theme>(
  // @ts-ignore Mui bug with spreading theme variables.
  (theme) =>
    createStyles({
      root: {
        display: 'flex'
      },
      cardListContainer: {
        flex: 1
      }
    }),
  { name: 'Mui-CardWithImageList' }
);

export default CardWithImageList;
