import React from 'react';
import parse from 'html-react-parser';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';

import { ComponentBase } from '../../models/models';

export interface SelectOption {
  uuid: string | number;
  display: string;
  secondaryText?: string;
  value?: Date;
}

export interface Props extends ComponentBase {
  /**
   * Whether the user is required to pick an option.
   */
  isRequired?: boolean;
  /**
   * A unique id for accessibility.
   */
  uuid: string;
  /**
   * A short title displayed above the field.
   */
  label: string;
  /**
   * Array of {uuid:display} pairs
   */
  options: SelectOption[] | null;
  /**
   * Optional prop for whether input should be disabled
   */
  disabled?: boolean;
  /**
   * Text displayed when no value is set.
   */
  placeholder?: string;
  /**
   * Short string below the select, useful for errors.
   */
  helperText?: string;
  /**
   * Red-ify the field.
   */
  error?: boolean;
  /**
   * currently selected value.
   */
  selectValue: number | string;
  /**
   * SVG element use as an icon when dropdown is closed
   */
  closedIcon?: string;
  /**
   * SVG element use as an icon when dropdown is opened
   */
  openedIcon?: string;
  /**
   * variant.
   */
  dropdownVariant?: 'filled' | 'standard' | 'outlined';
  /**
   * function triggered to handle a change.
   */
  handleChange: (ev: React.ChangeEvent<{ value: unknown }>) => void;
}

const SingleSelectBase: React.FC<Props> = ({
  isRequired,
  uuid,
  label,
  options,
  disabled,
  placeholder = '',
  helperText = '',
  error = false,
  selectValue,
  closedIcon,
  openedIcon,
  dropdownVariant = 'standard',
  handleChange
}) => {
  const classes = useStyles();
  const useCustomIcons = closedIcon && openedIcon;
  const [endAdornment, setEndAdornment] = React.useState<string | undefined>(
    useCustomIcons ? (closedIcon as string) : undefined
  );

  const handleOpen = () => {
    if (useCustomIcons) {
      setEndAdornment(openedIcon);
    }
  };

  const handleClose = () => {
    if (useCustomIcons) {
      setEndAdornment(closedIcon);
    }
  };

  return (
    <FormControl
      required={isRequired}
      fullWidth
      error={error}
      className={classes.root}
      variant={dropdownVariant}
    >
      <InputLabel id={`${uuid}-label`} className={classes.label} shrink>
        {label}
      </InputLabel>
      <Select
        disabled={disabled}
        displayEmpty
        value={selectValue}
        onChange={handleChange}
        id={uuid}
        labelId={`${uuid}-label`}
        IconComponent={
          endAdornment
            ? () =>
              React.cloneElement(parse(endAdornment) as JSX.Element, {
                className: classes.inputIcon
              })
            : undefined
        }
        onOpen={handleOpen}
        onClose={handleClose}
        classes={{
          root: classes.input,
          icon: classes.inputIcon,
          iconOpen: classes.inputIconOpen,
        }}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
          },
          getContentAnchorEl: null,
          transitionDuration: 0,
          classes: {
            paper: classes.menu
          }
        }}
        data-testid="select"
      >
        <MenuItem classes={{ root: classes.menuItem }} value="" disabled>
          <span className={classes.placeholder}>{placeholder}</span> 
        </MenuItem>

        {!!options &&
          options.map((opt: SelectOption) => {
            return (
              <MenuItem
                value={opt.uuid}
                key={opt.uuid}
                classes={{
                  root: classes.menuItem,
                  selected: classes.menuItemSelected
                }}
                disableRipple
                data-testid="select-option"
              >
                <div className={classes.itemPrimaryText}>
                  {decodeURIComponent(opt.display)}
                </div>
                {opt.secondaryText && (
                  <div className={classes.itemSecondaryText}>
                    {decodeURIComponent(opt.secondaryText)}
                  </div>
                )}
              </MenuItem>
            );
          })}
      </Select>

      {helperText && (
        <FormHelperText className={classes.helperText}>
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {},
      label: {},
      input: {},
      inputIcon: {
        pointerEvents: 'none',
        position: 'absolute',
        right: 0
      },
      inputIconOpen: {},
      menu: {},
      menuItem: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center'
      },
      menuItemSelected: {},
      helperText: {},
      placeholder: {},
      itemPrimaryText: {},
      itemSecondaryText: {
        fontSize: '12px',
        color: theme.palette.grey[600],
        '$input > &': {
          display: 'none'
        }
      }
    }),
  { name: 'Mui-SingleSelectBase' }
);

export default SingleSelectBase;