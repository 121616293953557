import React from 'react';
import parse from 'html-react-parser';
import { ComponentBase } from '../../../models/models';
import { useRadioDispatch, useRadioState } from '../../Core/radioContext';
import TypeInBase from '../../atoms/TypeInBase';
import { useDebounce } from '../../../helpers/useDebounce';

export interface Props extends ComponentBase {
  /**
   * Whether the user is required to fill in the textfield.
   */
  isRequired?: boolean;
  /**
   * Label displayed on top of Textfield.
   */
  label: string;
  /**
   * Unique ID from Json.
   */
  uuid: string;
  /**
   * Placeholder on Textfield.
   */
  placeholder?: string;
  /**
   * defaultValue on Textfield.
   */
  defaultValue?: string;
  /**
   * SVG element use as an icon when input is valid
   */
  validIcon?: string;
  /**
   * SVG element use as an icon when input is not valid
   */
  errorIcon?: string;
  /**
   * max number of characters allowed
   */
  maxCharLength?: number;
  /**
   * exact number of characters allowed
   */
  exactCharLength?: number;
  /**
   * number of characters needed in order to be a valid input
   */
  minCharLength?: number;
}

const TypeInNumber: React.FC<Props> = ({
  isRequired = true,
  uuid,
  placeholder,
  label,
  validIcon,
  errorIcon,
  defaultValue,
  maxCharLength,
  exactCharLength,
  minCharLength = 0
}) => {
  const { registerResponseComponent, logResponse } = useRadioDispatch();
  const radioState = useRadioState();

  const initValue = radioState.apiData.responses?.[uuid] || defaultValue || '';

  const [value, setValue] = React.useState<string>(initValue);
  const [error, setError] = React.useState<boolean>(false);
  const [endAdornment, setEndAdornment] = React.useState<string>();
  const errorMessage = 'Invalid input.';

  // Register component up in context.
  React.useEffect(() => {
    registerResponseComponent({
      componentType: 'input',
      componentId: uuid,
      value: initValue || null,
      isRequired
    });
  }, [registerResponseComponent, uuid, isRequired, initValue]);

  const validateNumberInput = (input: string) => {
    if (exactCharLength) {
      return isRequired ? input.trim().length === exactCharLength : true;
    }
    return isRequired ? input.trim().length > minCharLength : true;
  };

  const debounce = useDebounce((id, input) =>
    logResponse({ componentId: id, value: input })
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    let input = null;
    if (
      !Number.isNaN(Number(e.target.value)) &&
      !isNaN(parseFloat(e.target.value))
    ) {
      setValue(e.target.value);
      input = validateNumberInput(e.target.value) ? e.target.value : null;
    } else if (e.target.value === '') {
      setValue('');
    }

    // debounce logResponse dispatch with default 350ms delay
    debounce(uuid, input);
  };

  const handleOnBlur = () => {
    const isValid = validateNumberInput(value);

    setError(!isValid);
    if (validIcon && isValid) {
      setEndAdornment(validIcon);
    }
    if (errorIcon && !isValid) {
      setEndAdornment(errorIcon);
    }
  };

  return (
    <TypeInBase
      isRequired={isRequired}
      uuid={uuid}
      label={label}
      placeholder={placeholder}
      maxCharLength={exactCharLength || maxCharLength}
      value={value}
      handleChange={handleChange}
      handleOnBlur={handleOnBlur}
      errorMessage={error ? errorMessage : null}
      error={error}
      endAdornment={
        endAdornment ? (parse(endAdornment) as JSX.Element) : undefined
      }
    />
  );
};

export default TypeInNumber;
