import React from 'react';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import FormHelperText from '@material-ui/core/FormHelperText';
import { makeStyles, createStyles } from '@material-ui/core/styles';

import { useRadioDispatch, useRadioState } from '../../Core/radioContext';
import { ComponentBase } from '../../../models/models';
import { useDebounce } from '../../../helpers/useDebounce';

export interface Props extends ComponentBase {
  /** Whether the user is required to fill in the textfield. */
  isRequired?: boolean;
  /** Label displayed on top of Textfield. */
  label: string;
  /** Unique ID from Json. */
  uuid: string;
  /** Placeholder on Textfield. */
  placeholder?: string;
}

const TypeInSsn: React.FC<Props> = ({
  isRequired = true,
  label,
  uuid,
  placeholder = '●●●-●●-●●●●'
}) => {
  const { registerResponseComponent, logResponse } = useRadioDispatch();
  const radioState = useRadioState();
  const classes = useStyles();

  const initValue = radioState.apiData.responses?.[uuid] || '';

  // Using object for value so React re-renders every time setValue is called.
  const [value, setValue] = React.useState({ ssn: '' });
  const [error, setError] = React.useState<boolean>(false);
  const [inputFocused, setInputFocused] = React.useState<boolean>(false);
  const inputRef = React.useRef<HTMLInputElement | null>(null);
  const caretPosRef = React.useRef<number>(0);

  // Register component up in context.
  React.useEffect(() => {
    registerResponseComponent({
      componentType: 'input',
      componentId: uuid,
      value: initValue || null,
      isRequired
    });
    registerResponseComponent({
      componentType: 'input',
      componentId: `${uuid}_ff`,
      value: formatSsnNumber(initValue) || null,
      isRequired
    });
  }, [registerResponseComponent, uuid, isRequired, initValue]);

  const isValid = (val: string) => {
    // Validation turned off per Ticket 1295 on 9/30/2021
    // const re = /^(?!000|666)[0-8][0-9]{2}-(?!00)[0-9]{2}-(?!0000)[0-9]{4}$/;

    // Simply validate that it has 9 digits
    const re = /^[0-9]{3}-[0-9]{2}-[0-9]{4}$/;
    return re.test(val);
  };

  const debouncedLog = useDebounce((val) => {
    logResponse({
      componentId: uuid,
      value: val ? val.replace(/[^\d]/g, '') : val
    });
    logResponse({
      componentId: `${uuid}_ff`,
      value: val
    });
  });

  const maskValue = (val: string) => {
    if (inputFocused) return val;
    return val.replace(/\d/g, '●');
  };

  const formatSsnNumber = (str: string) => {
    let val = str.replace(/[^\d]/g, '').slice(0, 9);
    if (val.length >= 4) val = `${val.slice(0, 3)}-${val.slice(3)}`;
    if (val.length >= 7) val = `${val.slice(0, 6)}-${val.slice(6)}`;
    return val;
  };

  const handleChange = (ev: React.ChangeEvent<HTMLInputElement>): void => {
    caretPosRef.current = ev.target.selectionStart || 0;

    // In a pure-numerical entry, what is our position?
    caretPosRef.current -= (
      ev.target.value.slice(0, caretPosRef.current).match(/-/g) || []
    ).length;

    // Get a clean numerical input, inject dashes were expected.
    const val = formatSsnNumber(ev.target.value);
    setValue({ ssn: val });

    // Determine new position post-formatting.
    for (let i = 0; i < caretPosRef.current; i += 1) {
      if (val[i] === '-') caretPosRef.current += 1;
    }

    // Log value in radioState
    const radioStateVal = isValid(val) ? val : null;
    debouncedLog(radioStateVal);
  };

  React.useLayoutEffect(() => {
    if (inputRef.current && inputFocused) {
      inputRef.current.selectionStart = caretPosRef.current;
      inputRef.current.selectionEnd = caretPosRef.current;
    }
  });

  return (
    <FormControl
      className={classes.root}
      disabled={false}
      error={error}
      required={isRequired}
      fullWidth
    >
      <InputLabel className={classes.root} htmlFor={uuid} shrink>
        {label}
      </InputLabel>

      <div className={classes.inputWrap}>
        <Input
          className={classes.input}
          id={uuid}
          value={value.ssn}
          inputRef={inputRef}
          onChange={handleChange}
          onFocus={() => setInputFocused(true)}
          onBlur={() => {
            setInputFocused(false);
            setError(!isValid(value.ssn));
          }}
          inputProps={{
            maxLength: 11
          }}
        />

        <FormControl>
          {/* Using an inner FormControl because MUI gets upset otherwise. */}
          <Input
            classes={{ input: classes.inputDisplay }}
            inputProps={{
              'aria-hidden': true
            }}
            id={`${uuid}-display`}
            value={maskValue(value.ssn)}
            placeholder={placeholder}
            disableUnderline
            disabled
          />
        </FormControl>
      </div>

      {error && (
        <FormHelperText className={classes.helperText}>
          Please enter a valid social security number.
        </FormHelperText>
      )}
    </FormControl>
  );
};

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {},
      label: {},
      input: {
        caretColor: theme.palette.text.primary,
        color: 'transparent',
        zIndex: 5
      },
      inputDisplay: {
        color: `${theme.palette.text.primary}`,
        WebkitTextFillColor: theme.palette.text.primary,
        zIndex: 0
      },
      inputWrap: {
        display: 'grid',
        flexDirection: 'column',
        marginTop: 16, // from label + .MuiInput-formControl
        position: 'relative',
        '& > *': {
          gridArea: '1 / 1'
        }
      },
      underline: {},
      helperText: {}
    }),
  { name: 'Mui-TypeInSsn' }
);

export default TypeInSsn;
