import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { ComponentWithChildren } from '../../models/models';


const LeftPanelContent: React.FC<ComponentWithChildren> = ({children}) => {
  const classes = useStyles();
  return <div className={classes.root}>{children}</div>;
};

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        // position: 'fixed', was causing ProgressStage span to not wrap. Commented in case it breaks something
        '@media (max-width: 1024px)': {
          display: 'none'
        },
        '& > *:not(:last-child)': {
          marginBottom: theme.spacing(4)
        }
      }
    }),
  { name: 'Mui-LeftPanelContent' }
);

export default LeftPanelContent;