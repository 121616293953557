import React from 'react';
import { useRadioState, useRadioDispatch } from './radioContext';
import { continueRadio } from '../../services/radioApi';
import segment from '../../helpers/segmentHelper';
import { handleAuthCheck } from '../../helpers/cognitoAuth';
import { getConfig } from '../../helpers/config';

type HistoryItem = {
  state: Record<string, string>;
  title: string;
  url: string;
};

type History = {
  stack: HistoryItem[];
  currentIndex: number;
};

type ContextType = {
  history: History;
  pushToHistory: (
    experienceProcessId: string,
    flowId: string,
    stepId: string,
    stepUuid: string,
    stepName?: string
  ) => void;
  goPreviousStep: () => void;
};

export const NavigationContext = React.createContext<ContextType>({
  history: {
    stack: [],
    currentIndex: -1
  },
  pushToHistory: () => {},
  goPreviousStep: () => {}
});

export const NavigationProvider: React.FC = ({ children }) => {
  const config = getConfig();
  const USE_BROWSER_NAVIGATION = config.sdkUseBrowserNavigation === true;

  const { beginStep } = useRadioDispatch();
  const { profileUuid, traceId } = useRadioState();
  const [history, setHistory] = React.useState<History>({
    stack: [],
    currentIndex: -1
  });
  const basePath = React.useRef(window.location.pathname);
  const baseTitle = React.useRef(window.document.title);

  /**
   * camelKebab()
   * Converts camelCase flowId/stepIds to kebab-case URL params.
   *
   * @param camelString  a string in camelCase.
   * @returns a string in kebab case.
   */
  const camelKebab = (camelString: string) =>
    camelString.replace(/([A-Z])/g, '-$1').toLowerCase();

  /**
   * setWindowTitle()
   * Sets the browser's window/tab text if native navigation is enabled.
   *
   * @param stepName  human-readable string to display on the tab.
   */
  const setWindowTitle = (stepName: string = '') => {
    if (USE_BROWSER_NAVIGATION) {
      const newDocTitle = stepName
        ? `${stepName} • ${baseTitle.current}`
        : baseTitle.current;
      window.document.title = newDocTitle;
    }
  };

  /**
   * pushToHistory()
   * Handle updating our local history stack and setting the
   * browser URL + title.
   *
   * @param experienceProcessId  id of the current experienceProcess.
   * @param flowId  id of the current flow.
   * @param stepId  id of the current step.
   * @param stepUuid  unique identifier from this step's move.
   * @param stepName a human-readable name of the step. (optional)
   */
  const pushToHistory = React.useCallback(
    (
      experienceProcessId: string,
      flowId: string,
      stepId: string,
      stepUuid: string,
      stepName: string = ''
    ) => {
      const pathname = [
        `${basePath.current.replace(/\/$/, '')}`,
        camelKebab(flowId),
        camelKebab(stepId),
        stepUuid,
        '' // Empty segment for trailing slash
      ].join('/');

      const state = {
        experienceProcessId,
        flowId,
        stepId,
        stepUuid,
        stepName
      };

      setHistory(({ stack, currentIndex }) => {
        let oldStack = stack;

        // If we're not at the top of the stack
        // AND the pushed stepUuid is not next in stack state,
        // we forked so stack needs to get sliced.
        if (
          currentIndex < stack.length - 1 &&
          oldStack[currentIndex + 1].state.stepUuid !== stepUuid
        ) {
          oldStack = oldStack.slice(0, currentIndex + 1);
        }

        if (USE_BROWSER_NAVIGATION) {
          // Set URL + browser history.
          // If stack is empty, replace the current "entryway" URL.
          if (oldStack.length === 0) {
            window.history.replaceState(state, stepName, pathname);
          } else {
            window.history.pushState(state, stepName, pathname);
          }

          // Reset window scroll position and set window title
          // to simulate a "page load."
          window.scrollTo(0, 0);
          setWindowTitle(stepName);
        }

        // If we're not at the top of the stack
        // AND the pushed stepUuid is next in stack,
        // just update the currentIndex and end this.
        if (
          currentIndex < oldStack.length - 1 &&
          oldStack[currentIndex + 1].state.stepUuid === stepUuid
        ) {
          return {
            currentIndex: currentIndex + 1,
            stack: oldStack
          };
        }

        // Build new stack.
        const newStack = [
          ...oldStack,
          { state, title: stepName, url: pathname }
        ];

        return {
          stack: newStack,
          currentIndex: newStack.length - 1
        };
      });
    },
    []
  );

  /**
   * navigateTo()
   * Calls /continue with the requested stepUuid and updates history state.
   *
   * @param reqExperienceProcessId  requested ExperienceProcessId.
   * @param reqStepUuid  the stepUuid we would like to go to.
   */
  const navigateTo = React.useCallback(
    async (reqExperienceProcessId: string, reqStepUuid: string) => {
      const continueReq = {
        traceId,
        requestData: {
          experienceProcessId: reqExperienceProcessId,
          stepUuid: reqStepUuid,
          profileUuid
        }
      };

      const newPositionIndex = history.stack.findIndex(
        (item) => item.state.stepUuid === reqStepUuid
      );
      if (newPositionIndex > -1) {
        setWindowTitle(history.stack[newPositionIndex].title);
        setHistory(({ stack }) => {
          return {
            stack,
            currentIndex: newPositionIndex
          };
        });
      }

      try {
        const continueRes = await continueRadio(continueReq);

        const data = continueRes.responseData;
        const apiData = data.uiData ? data.uiData : {};

        // Trigger Segment pageview.
        segment.page(data.stepId);

        // Auth check.
        await handleAuthCheck(data.step);

        // Update app state.
        beginStep({
          profileUuid: data.profileUuid,
          flowId: data.flowId,
          stepId: data.stepId,
          stepUuid: data.stepUuid,
          nodeTree: data.step.nodeTree,
          isLoading: false,
          hasLoadingScreen: false,
          apiData
        });

        // If stepUuid doesn't match the req, we forked, call pushToHistory.
        if (history.stack[newPositionIndex].state.stepUuid !== data.stepUuid) {
          pushToHistory(
            data.experienceProcessId,
            data.flowId,
            data.stepId,
            data.stepUuid,
            data.step.name
          );
        }
      } catch (err) {
        console.error(err);
      }
    },
    [profileUuid, history, beginStep, traceId, pushToHistory]
  );

  /**
   * goPreviousStep
   * Navigate to the last step either using native browser history
   * or artificial SDK history.
   */
  const goPreviousStep = () => {
    if (USE_BROWSER_NAVIGATION) {
      window.history.go(-1);
      return;
    }

    const prevIndex = history.currentIndex > 0 ? history.currentIndex - 1 : 0;
    const prevState = history.stack[prevIndex].state;
    const reqExperienceProcessId = prevState.experienceProcessId;
    const reqStepUuid = prevState.stepUuid;
    navigateTo(reqExperienceProcessId, reqStepUuid);
  };

  /**
   * Bootstrap useEffect
   * The thing we do when window.location unexpectedly changes (popstate),
   * probably from someone using the native browser controls.
   */
  React.useEffect(() => {
    const unexpectedUrlChange = () => {
      if (USE_BROWSER_NAVIGATION) {
        const reqExperienceProcessId = window.history.state.experienceProcessId;
        const reqStepUuid = window.history.state.stepUuid;
        navigateTo(reqExperienceProcessId, reqStepUuid);
      }
    };

    window.addEventListener('popstate', unexpectedUrlChange);
    return () => window.removeEventListener('popstate', unexpectedUrlChange);
  }, [navigateTo]);

  return (
    <NavigationContext.Provider
      value={{ history, pushToHistory, goPreviousStep }}
    >
      {children}
    </NavigationContext.Provider>
  );
};

export const useNavigationContext = () => {
  const context = React.useContext(NavigationContext);

  if (context === undefined) {
    throw new Error(
      'useNavigationContext must be used within NavigationProvider.'
    );
  }

  return {
    historyLength: context.history.stack.length,
    currentIndex: context.history.currentIndex,
    pushToHistory: context.pushToHistory,
    goPreviousStep: context.goPreviousStep,

    // dev debug values (to be removed)
    historyStack: context.history.stack
  };
};
