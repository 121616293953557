import React from 'react';

import { ComponentBase } from '../../models/models';
import { useRadioDispatch, useRadioState } from '../Core/radioContext';
import { useNavigationContext } from '../Core/navigationContext';
import FabBase from '../atoms/FabBase';

export interface Props extends ComponentBase {
  /**
   * If true increases the width of the Fab in order to fit its content
   */
  extended?: boolean;
  /**
   * SVG element use as an icon
   */
  icon?: string;
  /**
   * Text inside Fab
   */
  label?: string;
  /**
   * Display variants.
   */
  variant?: 'default' | 'ghost' | 'inverse';
  /**
   * Function type, ie: forward, back, other.
   */
  type: 'forward' | 'back' | 'forwardReadOnly' | 'forwardOnEmpty' | string;
  /**
   * If true button will be span the available space.
   */
  fullWidth?: boolean;
  /**
   * Uuid for button
   */
  uuid: string;
  /**
   * If true button will be disabled.
   */
  disabled?: boolean;
  /**
   * If true button will be hidden but occupying space.
   */
  hidden?: boolean;
  /**
   * The function that will be triggered onClick if type is neither back nor forward.
   */
  onClick?: () => void;
  /**
   * If true ripple will be disabled
   */
  disableRipple?: boolean;
}

const FabNavigation: React.FC<Props> = ({
  extended = false,
  icon,
  label,
  variant = 'default',
  type,
  fullWidth = false,
  uuid,
  disabled = false,
  hidden,
  onClick,
  disableRipple
}) => {
  const radioState = useRadioState();
  const {
    registerResponseComponent,
    logResponse,
    finishStep
  } = useRadioDispatch();
  const { goPreviousStep } = useNavigationContext();
  const [isDisabled, setIsDisabled] = React.useState<boolean>(disabled);

  const hasDefaultValue =
    radioState.apiData.responses &&
    Object.keys(radioState.apiData.responses).length > 0;

  const hideForwardButton = hidden && !hasDefaultValue;

  React.useEffect(() => {
    // register the component as false
    registerResponseComponent({
      componentType: 'button',
      componentId: uuid,
      value: false,
      isRequired: false
    });
  }, [registerResponseComponent, uuid]);

  const handleForwardClick = () => {
    logResponse({
      componentId: uuid,
      value: true
    });
    finishStep();
  };

  const handleBackClick = () => {
    goPreviousStep();
  };

  let handleClick;
  if (
    type === 'forward' ||
    type === 'forwardReadOnly' ||
    type === 'forwardOnEmpty'
  ) {
    handleClick = handleForwardClick;
  } else if (type === 'back') {
    handleClick = handleBackClick;
  } else if (onClick) {
    handleClick = onClick;
  } else {
    handleClick = () => {};
  }

  React.useEffect(() => {
    // setting disabled state
    if (type === 'forward') {
      setIsDisabled(disabled || !radioState.canComplete);
    } else if (type === 'forwardOnEmpty') {
      setIsDisabled(disabled || radioState.canComplete);
    } else {
      setIsDisabled(disabled);
    }
  }, [radioState, disabled, type]);

  return (
    <FabBase
      extended={extended}
      icon={icon}
      label={label}
      handleClick={handleClick}
      variant={variant}
      type={type}
      disabled={isDisabled}
      fullWidth={fullWidth}
      hidden={type === 'forward' ? hideForwardButton : hidden}
      disableRipple={disableRipple}
    />
  );
};

export default FabNavigation;
