import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import * as filestack from 'filestack-js';
import { PickerResponse, PickerFileMetadata } from 'filestack-js';

import { useRadioDispatch } from '../../Core/radioContext';
import { getConfig } from '../../../helpers/config';
import { ComponentBase } from '../../../models/models';
import TileButtonBase from '../../atoms/TileButtonBase';

/**
 * FILESTACK SECURITY SETTINGS
 * This component requires three SDK environment variables set (see above).
 * Filestack provides the API_KEY, the other two (SECURITY_POLICY,
 * SECURITY_SIGNATURE) need to be calculated using the account's secret.
 *
 * SECURITY_POLICY: a base64 hash of the security policy JSON. Docs do say
 * the value should be base64-URL-safe, running either btoa() or js-base64()
 * appears to produce working results.
 *
 * SECURITY_SIGNATURE: a HMAC-SHA256 string using the base64'ed security policy
 * as the message, and the account's secret for the key.
 *
 * See https://github.com/TugboatHoldings/filestack-security-generator
 */

// https://bit.ly/35QZuNi
const FILE_TYPES: Record<string, string> = {
  image: 'image/*',
  images: 'image/*',
  video: 'video/*',
  videos: 'video/*',
  audio: 'audio/*',
  text: 'text/*',
  texts: 'text/*'
};

export interface Props extends ComponentBase {
  /** Unique ID from Json. */
  uuid: string;
  /** The user must interact with this comp to enable the forward button. */
  isRequired?: boolean;
  /** Max number of files a user can select. */
  maxFiles?: number;
  /** Text displayed on button. */
  label?: string;
  /** Text displayed on button when a file has been uploaded. */
  labelWithFiles?: string;
  /** Text displayed on button when maxFiles has been reached. */
  labelMaxFiles?: string;
  /** Parsable markup (like an SVG) to render as an icon. */
  icon?: string;
  /** Url to an image to use as an icon, icon prop takes priority. */
  iconUrl?: string;
  /** Where to render the icon. */
  iconPosition?: 'default' | 'leading' | 'trailing';
  /** Restrict upload to specific file types, use "image", "video", "audio", "text" or list the specific file extensions (i.e. ".pdf"). */
  fileTypesAccepted?: string[];
  /** Automatically trigger /complete on successful upload. */
  autoCompleteOnUpload?: boolean;
  /** Provide visual confirmation of the files successfully uploaded. */
  displayUploadedList?: boolean;
}

const FilestackUpload: React.FC<Props> = ({
  uuid,
  label = 'Upload to Filestack',
  labelWithFiles = '',
  labelMaxFiles = '',
  isRequired = true,
  maxFiles = 1,
  icon,
  iconUrl,
  iconPosition = 'default',
  fileTypesAccepted = [],
  autoCompleteOnUpload = false,
  displayUploadedList = true
}) => {
  const classes = useStyles();
  const config = getConfig();
  const FILESTACK_API_KEY = config.filestackApiKey;
  const FILESTACK_SECURITY_POLICY = config.filestackSecurityPolicy;
  const FILESTACK_SECURITY_SIGNATURE = config.filestackSecuritySignature;
  const {
    registerResponseComponent,
    logResponse,
    finishStep
  } = useRadioDispatch();
  const [fileState, setFileState] = React.useState<PickerFileMetadata[]>([]);

  // Register component up in context.
  React.useEffect(() => {
    registerResponseComponent({
      componentType: 'filestackUploadList',
      componentId: uuid,
      value: null,
      isRequired
    });
  }, [registerResponseComponent, uuid, isRequired]);

  if (
    !FILESTACK_API_KEY ||
    !FILESTACK_SECURITY_POLICY ||
    !FILESTACK_SECURITY_SIGNATURE
  ) {
    console.error(
      'RadioSDK config requires filestackApiKey, filestackSecurityPolicy, filestackSecuritySignature to be set in order to use <FilestackUpload />.'
    );
    return <>{'Unable to render <FilestackUpload/>, see console.'}</>;
  }

  const filestackClient = filestack.init(FILESTACK_API_KEY, {
    security: {
      policy: FILESTACK_SECURITY_POLICY,
      signature: FILESTACK_SECURITY_SIGNATURE
    }
  });

  const onUploadDone = (files: PickerResponse) => {
    const newState = [...fileState, ...files.filesUploaded];
    setFileState(newState);
    logResponse({
      componentId: uuid,
      value: newState
    });

    if (autoCompleteOnUpload) {
      finishStep();
    }
  };

  const translateFileTypes = (fileTypes: string[] = []) => {
    return fileTypes.map((item) => FILE_TYPES[item] || item);
  };

  const pickerConfig = {
    fromSources: ['local_file_system'],
    transformations: {
      crop: false,
      circle: false,
      rotate: true
    },
    ...(fileTypesAccepted.length > 0
      ? { accept: translateFileTypes(fileTypesAccepted) }
      : {}),
    maxFiles,
    onUploadDone
  };

  const buttonLabel = () => {
    let btnLabel = label;

    if (labelWithFiles && fileState.length > 0) {
      btnLabel = labelWithFiles;
    }
    if (labelMaxFiles && fileState.length >= maxFiles) {
      btnLabel = labelMaxFiles;
    }

    return btnLabel;
  };

  return (
    <div className={classes.root}>
      <TileButtonBase
        uuid={uuid}
        label={buttonLabel()}
        icon={icon}
        iconUrl={iconUrl}
        iconPosition={iconPosition}
        onClick={() => filestackClient.picker(pickerConfig).open()}
        disabled={fileState.length >= maxFiles}
      />
      {displayUploadedList && fileState.length > 0 && (
        <>
          <h3 className={classes.uploadListHeading}>Uploaded</h3>
          <ul className={classes.uploadList}>
            {fileState.map((file) => (
              <li key={file.handle}>{file.filename}</li>
            ))}
          </ul>
        </>
      )}
    </div>
  );
};

const useStyles = makeStyles(
  (theme) => ({
    root: {},
    uploadListHeading: {
      ...theme.typography.h3,
      margin: '0.35em 0'
    },
    uploadList: {
      ...theme.typography.body1
    }
  }),
  { name: 'Mui-FilestackUpload' }
);

export default FilestackUpload;
