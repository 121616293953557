import React from 'react';

import { useNavigationContext } from './navigationContext';

const NavDebugger: React.FC = () => {
  const { historyLength, historyStack, currentIndex } = useNavigationContext();
  const [logStack, setLogStack] = React.useState(false);

  React.useEffect(() => {
    if (logStack) {
      console.log('stack changed!');
      console.table(historyStack, ['index', 'title', 'url']);
      console.log(`current index is ${currentIndex}`);
      console.log('');
      console.log(' ');
    }
  }, [historyStack, currentIndex]);

  return (
    <div
      style={{
        background: 'red',
        position: 'fixed',
        top: 0,
        right: 0,
        color: 'white',
        zIndex: 2147483640,
        padding: 5
      }}
    >
      <span style={{ display: 'block' }}>
        {`win.history.length: ${window.history.length}`}
      </span>
      <span style={{ display: 'block' }}>
        {`historyLength: ${historyLength}`}
      </span>
      <span style={{ display: 'block' }}>
        {`currentIndex: ${currentIndex}`}
      </span>

      <label htmlFor="showStackTable">
        <input
          type="checkbox"
          id="showStackTable"
          name="showStackTable"
          checked={logStack}
          onChange={() => setLogStack(!logStack)}
        />
        Log SDK history
      </label>

      <button
        type="button"
        style={{ display: 'block', width: '100%' }}
        onClick={() => console.log(window.history.state)}
      >
        log browser current
      </button>

      <button
        type="button"
        style={{ display: 'block', width: '100%' }}
        onClick={() => console.log(historyStack)}
      >
        log sdk stack
      </button>

      <button
        type="button"
        style={{ display: 'block', width: '100%' }}
        onClick={() => console.log(JSON.stringify(historyStack))}
      >
        log sdk stack (stringify)
      </button>
    </div>
  );
};

export default NavDebugger;
